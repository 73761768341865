import React, { useState, useEffect } from 'react';
import shortid from 'shortid';
import {
  Box,
  Drawer,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import CloseIcon from '@mui/icons-material/Close';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdTypography,
  FdMarkdownRender,
  FdChips,
  FdTooltip,
  FdFileAttachment,
} from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import setAppMarginRightByIds from '../../../shared/utils/layout';
import { getTagColor } from '../../../shared/utils/tagUtils';
import { sortByDateField } from '../../../shared/utils/dateUtils';
import { getDifficultyLevel } from '../../../shared/utils/difficultyMapping';
import { capitalize } from '../../../shared/utils/stringUtils';
import { PROFICIENCY_LEVELS } from '../../../constants';

const drawerWidth = 400;

const useStyles = makeStyles()(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
}));

const ViewTaskDrawer = ({
  activeTaskData,
  openDrawer,
  openDrawerCallBack,
  mainPageIds,
}) => {
  const [activeTask, setActiveTask] = useState(activeTaskData);

  const { classes } = useStyles();

  const ContentWithLabel = ({ label, content }) => (
    <Box display="flex" alignItems="center" mb={2}>
      <Box width="120px">
        <FdTypography variant="subtitle1">{label}</FdTypography>
      </Box>
      {content}
    </Box>
  );

  ContentWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  const LabelWithTooltip = ({ label, tooltipText }) => (
    <Box display="flex" alignItems="center">
      <FdTypography variant="subtitle1">{label}</FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="large">
          <InfoOutlinedIcon />
        </IconButton>
      </FdTooltip>
    </Box>
  );
  LabelWithTooltip.propTypes = {
    label: PropTypes.string.isRequired,
    tooltipText: PropTypes.node.isRequired,
  };

  useEffect(() => {
    setActiveTask(activeTaskData);
  }, [activeTaskData]);

  useEffect(() => {
    setAppMarginRightByIds(mainPageIds, '400px');
  });

  const tagsData =
    activeTask?.tags?.sort(sortByDateField('updatedAt', 'desc')).map((t) => ({
      label: t.Tag?.name,
      color: t.Tag?.color && getTagColor(t.Tag?.color),
    })) || [];

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openDrawer}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Card heading="Test" style={{ overflow: 'auto' }}>
        <CardHeader
          action={
            <IconButton
              onClick={() => {
                setAppMarginRightByIds(mainPageIds, '0');
                openDrawerCallBack(false);
              }}
              size="large"
            >
              <CloseIcon style={{ fontSize: 28 }} />
            </IconButton>
          }
          title={activeTask?.name}
        />
        <CardContent style={{ height: '795px' }}>
          <ContentWithLabel
            label="Provided By"
            content={
              <FdTypography variant="body1" color="secondary">
                {activeTask?.org?.name}
              </FdTypography>
            }
          />
          <ContentWithLabel
            label="Professional Specialty"
            content={
              <FdTypography
                color="secondary"
                variant="body1"
                data-cy="specialty"
              >
                {activeTask?.specialty?.name}
              </FdTypography>
            }
          />
          <ContentWithLabel
            label="Proficiency"
            content={
              <FdTypography
                color="secondary"
                variant="body1"
                data-cy="proficiency"
              >
                {activeTask?.difficulty > 5
                  ? getDifficultyLevel(activeTask?.difficulty)
                  : PROFICIENCY_LEVELS[activeTask?.difficulty]}
              </FdTypography>
            }
          />
          <ContentWithLabel
            label="Type"
            content={
              <FdTypography
                color="secondary"
                variant="body1"
                data-cy="proficiency"
              >
                {capitalize(activeTask?.type)}
              </FdTypography>
            }
          />
          <ContentWithLabel
            label="Points"
            content={
              <FdTypography
                color="secondary"
                variant="body1"
                data-cy="recommended-points"
              >
                {activeTask?.recommendedPoints}
              </FdTypography>
            }
          />
          <ContentWithLabel
            label="Est. Time to Solve"
            content={
              <FdTypography
                color="secondary"
                variant="body1"
                data-cy="estimated-solve-time"
              >
                {`${activeTask?.estimatedSolveTime} (min)`}
              </FdTypography>
            }
          />
          {activeTask?.description && (
            <Box mb={2} className={classes.description}>
              <LabelWithTooltip
                label="Description"
                tooltipText="Description is viewable by participants for challenge instructions or other related details."
              />
              <FdTypography variant="body1" color="secondary">
                <ShowMoreText
                  more="See more"
                  less="See less"
                  anchorClass="see-more"
                >
                  <FdMarkdownRender markdown={activeTask?.description} />
                </ShowMoreText>
              </FdTypography>
            </Box>
          )}

          {activeTask?.summary && (
            <Box mb={2} className={classes.description}>
              <LabelWithTooltip
                label="Summary"
                tooltipText="Summary is viewable by admins/managers for quick reference and overview of the challenge for  use-cases such as challenge curation for events, leasing challenges etc."
              />
              <FdTypography variant="body1" color="secondary">
                <ShowMoreText
                  more="See more"
                  less="See less"
                  anchorClass="see-more"
                >
                  <FdMarkdownRender markdown={activeTask?.summary} />
                </ShowMoreText>
              </FdTypography>
            </Box>
          )}

          {activeTask?.skills?.length > 0 && (
            <Box mb={2}>
              <LabelWithTooltip
                label="Skills"
                tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
              />
              <FdTypography variant="body1" color="secondary">
                {activeTask?.skills.map((item) => (
                  <ul key={shortid.generate()} data-cy="skills">
                    <li>{item}</li>
                  </ul>
                ))}
              </FdTypography>
            </Box>
          )}
          {activeTask?.techniqueTags?.length > 0 && (
            <Box mb={2}>
              <LabelWithTooltip
                label="Techniques"
                tooltipText="Techniques refer to the specific methods or strategies required to resolve a challenge."
              />
              <FdChips
                data={activeTask?.techniqueTags?.map((t) => ({
                  label: t,
                  color: 'default',
                }))}
              />
            </Box>
          )}
          {activeTask?.technologyTags?.length > 0 && (
            <Box mb={2}>
              <LabelWithTooltip
                label="Technologies"
                tooltipText="Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution."
              />
              <FdChips
                data={activeTask?.technologyTags?.map((t) => ({
                  label: t,
                  color: 'default',
                }))}
              />
            </Box>
          )}
          {activeTask?.labId && (
            <Box my={3}>
              <FdTypography variant="subtitle1" data-cy="lab-heading">
                Attached Lab
              </FdTypography>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mt={1}
                mb={2}
              >
                <FdTypography variant="body1" color="secondary">
                  {activeTask?.lab?.name}
                </FdTypography>
              </Box>
            </Box>
          )}
          {activeTask?.files?.items?.length !== 0 && (
            <Box mb={2}>
              <FdTypography variant="subtitle1" data-cy="attachments-subtitle">
                Attachments
              </FdTypography>
              {activeTask?.files?.items?.map((f) => (
                <FdFileAttachment name={f.name} />
              ))}
            </Box>
          )}
          {activeTask?.hints?.items?.length !== 0 && (
            <Box display="flex" flexDirection="column" mb={2}>
              <FdTypography variant="subtitle1">Hints</FdTypography>

              <FdTypography variant="body1" color="secondary">
                {activeTask?.hints?.items?.map((h, index) => (
                  <Box key={index}>
                    <Box display="flex" flexDirection="row" mt={1.5}>
                      <EmojiObjectsIcon />
                      <Box ml={1.5}>{h?.text}</Box>
                    </Box>
                  </Box>
                ))}
              </FdTypography>
            </Box>
          )}
          {tagsData?.length > 0 && (
            <Box display="flex" flexDirection="column" mb={2}>
              <Box display="flex" justifyContent="space-between">
                <Box width="120px">
                  <FdTypography variant="subtitle1">Tags</FdTypography>
                </Box>
                <FdTypography variant="body2">{tagsData?.length}</FdTypography>
              </Box>
              <Box my={1}>
                <FdChips data={tagsData} />
              </Box>
            </Box>
          )}
          <ContentWithLabel
            label="Flag"
            content={
              <FdTypography variant="body1" color="secondary">
                {activeTask?.answer}
              </FdTypography>
            }
          />
        </CardContent>
      </Card>
    </Drawer>
  );
};

ViewTaskDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeTaskData: PropTypes.object.isRequired,

  openDrawer: PropTypes.bool.isRequired,
  openDrawerCallBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mainPageIds: PropTypes.array,

  listHintRevealsData: PropTypes.shape({
    listHintReveals: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          hintId: PropTypes.string,
          taskAssessmentId: PropTypes.string,
        }),
      ),
    }),
  }),
};

ViewTaskDrawer.defaultProps = {
  mainPageIds: [],

  listHintRevealsData: {},
};

export default ViewTaskDrawer;
