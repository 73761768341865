import React, { useState } from 'react';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdProgress,
  useQueryRecursive,
  FdModal,
  successToastMessage,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { gql, useMutation, useQuery } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  listTasks,
  listOrgs,
  getTask,
  listTaskAttemptAggregatesByStatus,
} from '../graphql/queries';
import { allocateTasksToOrg } from '../graphql/mutations';
import {
  OrgContent,
  FdCatalogue,
  ContentTabs,
} from '../components/Organisation';
import {
  initialValues,
  validationSchema,
} from '../validation-schemas/Organisation';
import ChallengeUsageTable from '../components/ContentCatalogue/ChallengeUsageTable';
import { getDateTimeZoneCustom } from '../shared/utils/dateUtils';
import AddUsageImage from '../shared/images/add-usage.svg';

const ContentCatalogue = () => {
  const [selectedOrg, setSelectedOrg] = useState(false);
  const [selectedTask, setSelectedTask] = useState(undefined);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });

  const { watch, reset } = reactHookFormMethods;

  const {
    data: listTasksData,
    loading: listTasksLoading,
    refetch: refetchTasks,
  } = useQueryRecursive(gql(listTasks), {
    variables: {
      filter: {
        status: { ne: 'DRAFT' },
      },
      limit: 1000,
    },
  });

  const { data: getTaskData, loading: getTaskLoading } = useQuery(
    gql(getTask),
    {
      variables: {
        id: selectedTask,
      },
      skip: !selectedTask,
    },
  );

  const {
    data: listTaskAttemptsAggregatesData,
    loading: listTaskAttemptsAggregatesLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByStatus), {
    variables: {
      status: 'COMPLETED',
      limit: 1000,
    },
  });

  const noOfSolves = (value, _taskId) => {
    const listTaskAttemptByAssessmentLength =
      listTaskAttemptsAggregatesData?.listTaskAttemptAggregatesByStatus?.items?.find(
        (item) => value === item?.assessmentId && _taskId === item?.taskId,
      )?.attempts || 0;
    const listTaskAttemptByTaskLength =
      listTaskAttemptsAggregatesData?.listTaskAttemptAggregatesByStatus?.items?.find(
        (item) => value === item?.taskId,
      )?.attempts || 0;
    return { listTaskAttemptByAssessmentLength, listTaskAttemptByTaskLength };
  };

  const taskUsage = getTaskData?.getTask?.assessments?.items?.map(
    (assessment) => ({
      id: assessment?.assessment?.id,
      eventName: assessment?.assessment?.name,
      orgName: assessment?.assessment?.creator?.org?.name,
      time: getDateTimeZoneCustom(
        assessment?.assessment?.startDateTime,
        'MM/yyyy',
      ),
      noOfSolves: noOfSolves(assessment?.assessment?.id, assessment?.taskId)
        ?.listTaskAttemptByAssessmentLength,
    }),
  );

  const {
    data: listOrgsData,
    loading: listOrgsLoading,
    refetch: refetchlistOrgs,
  } = useQueryRecursive(gql(listOrgs));

  const [allocateTasksToOrgMutation] = useMutation(gql(allocateTasksToOrg));

  if (
    listTasksLoading ||
    listOrgsLoading ||
    listTaskAttemptsAggregatesLoading
  ) {
    return <FdProgress />;
  }

  const allTasks = listTasksData?.listTasks?.items
    ?.filter((t) => t?.type !== 'CONTAINER')
    ?.map((task) => ({
      ...task,
      specialtyName: task?.specialty?.name,
      skills: task?.specialty?.skills?.items.map((s) => s.name),
      techniqueTags: task?.skills?.items
        .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
        .flat(),
      technologyTags: task?.technologyTags?.items.map(
        (tt) => tt.technologyTag?.name,
      ),
      difficultyInteger: task?.difficulty,
      attempts: noOfSolves(task?.id)?.listTaskAttemptByTaskLength,
      assigned: [
        ...new Set(
          task.orgs?.items?.map((orgData) => orgData?.org?.name).flat(),
        ),
      ],
      noOfOrgs: [
        ...new Set(
          task.orgs?.items?.map((orgData) => orgData?.org?.name).flat(),
        ),
      ].length,
      noOfEvents: [
        ...new Set(
          task?.assessments?.items?.map(
            (assessmentData) => assessmentData?.assessmentId,
          ),
        ),
      ].length,
      type: task?.modulePart !== null ? 'Labs' : 'Static',
      public: task?.public === 'TRUE' ? 'Yes' : 'No',
    }));

  const watchTasks = watch('taskIds');

  const orgDetails = listOrgsData?.listOrgs?.items
    ?.map((item) => ({
      id: item?.id,
      name: item?.name,
      tasks: [
        ...new Set(
          item?.tasks?.items
            ?.filter((t) => t?.type !== 'CONTAINER')
            ?.map((i) => i?.task?.id)
            ?.flat(),
        ),
      ],
    }))
    ?.filter((o) => !o?.tasks?.some((tag) => new Set(watchTasks).has(tag)))
    .sort((a, b) => a?.name?.localeCompare(b?.name));

  const data = [
    {
      heading: 'Distribute Challenge',
      index: 0,
      data: (
        <Box className="flex gap-x-2">
          <Box width="70%">
            <Box className="w-full">
              <FormProvider {...reactHookFormMethods}>
                <form>
                  <OrgContent
                    data={allTasks}
                    orgDetails={orgDetails}
                    selectedOrg={setSelectedOrg}
                    contentCatalogue
                    refetchTasks={refetchTasks}
                  />
                </form>
              </FormProvider>
            </Box>
          </Box>
          <Box width="30%">
            <FdCatalogue orgDetails={orgDetails} allTasks={allTasks} />
          </Box>
        </Box>
      ),
    },
    {
      heading: 'View Usage Metrics',
      index: 1,
      data: (
        <Box className="flex gap-x-2">
          <Box height="750px" width="70%" data-cy="challenges-table">
            <ChallengeUsageTable
              rows={allTasks}
              onRowClick={(_value) => {
                setSelectedTask(_value?.id);
              }}
            />
          </Box>
          <Box width="30%" height="750px">
            {!selectedTask ? (
              <Box
                display="flex"
                height="100%"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
              >
                <FdTypography color="secondary" variant="subtitle1">
                  Find more information
                </FdTypography>
                <FdTypography color="secondary" variant="body2">
                  Select a Challenge row
                </FdTypography>
                <FdTypography color="secondary" variant="body2">
                  to see more details
                </FdTypography>
                <FdTypography color="secondary" variant="body2">
                  pertaining to its usage.
                </FdTypography>
                <Box my={4}>
                  <img src={AddUsageImage} alt="add-usage" />
                </Box>
              </Box>
            ) : (
              <ChallengeUsageTable
                rows={taskUsage}
                taskName={getTaskData?.getTask?.name}
                getTaskLoading={getTaskLoading}
                usageDetail
              />
            )}
          </Box>
        </Box>
      ),
    },
  ];

  return (
    <Box>
      <FdBreadcrumbHeader
        page={{ name: 'Challenges Store', type: 'CHALLENGE' }}
      />
      <Box my={2}>
        <FdPageHeading type="CHALLENGE">
          FifthDomain Challenges Store
        </FdPageHeading>
      </Box>
      <ContentTabs data={data} />
      <FdModal
        size="md"
        title={
          <Box display="flex" alignItems="center">
            <FdTypography variant="h3">
              {`Allocate ${watchTasks?.length} tasks to ${selectedOrg?.name}`}
            </FdTypography>
          </Box>
        }
        description={
          <FdTypography variant="body1" color="secondary">
            Press Save to assign challenges. If you wish to restart the process,
            press Cancel (Any unsaved changes will be lost.).
          </FdTypography>
        }
        confirm="SAVE"
        dismiss="CANCEL"
        open={selectedOrg}
        onConfirm={() => {
          if (watchTasks?.length > 0) {
            allocateTasksToOrgMutation({
              variables: {
                orgId: selectedOrg?.id,
                taskIds: watchTasks,
              },
              onCompleted: () => {
                successToastMessage(
                  `${watchTasks?.length} Challenges added to ${selectedOrg?.name}`,
                );
                refetchlistOrgs();
              },
            });
          }
          reset();
          setSelectedOrg(false);
        }}
        onDismiss={() => {
          setSelectedOrg(false);
        }}
      />
    </Box>
  );
};

export default ContentCatalogue;
