import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdHighlightChip } from '@fifthdomain/fe-shared';

const useStyles = makeStyles()(() => ({
  cellHeading: {
    lineHeight: '28px',
  },
}));

const ChallengeInfoCell = ({ values }) => {
  const { classes } = useStyles();
  const { difficulty, name, points, attempts, estimatedSolveTime } =
    values ?? {};

  return (
    <Box>
      <Box className={classes.cellHeading}>{name}</Box>
      <Box display="flex">
        <FdHighlightChip text={difficulty} condensed />
        {estimatedSolveTime && (
          <FdHighlightChip
            text={`Est. Time: ${estimatedSolveTime}`}
            condensed
          />
        )}
        {points && <FdHighlightChip text={`${points} Points`} condensed />}
        {attempts === 0 && (
          <FdHighlightChip text="Never Solved Before" condensed />
        )}
      </Box>
    </Box>
  );
};

ChallengeInfoCell.propTypes = {
  values: PropTypes.objectOf({
    difficulty: PropTypes.string,
    name: PropTypes.string,
    estimatedSolveTime: PropTypes.string,
  }).isRequired,
};

export default ChallengeInfoCell;
