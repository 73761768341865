import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { gql } from '@apollo/client';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdTypography,
  FdAccordion,
  FdTooltip,
  FdTab,
  useQueryRecursive,
  FdProgress,
} from '@fifthdomain/fe-shared';
import { useParams } from 'react-router-dom';
import SummaryTitle from './SummaryTitle';
import { BarNivo } from '../../Charts';
import HeatmapNivo from '../../Charts/HeatMapNivo';
import {
  getAssessmentChallengesTechniques,
  getAssessmentChallengesTechnologies,
  getSkillsProficienciesMappedData,
} from '../../../shared/utils/getAssessmentOverview';
import { PROFICIENCY } from '../../../constants';
import { capitalize } from '../../../shared/utils/stringUtils';
import useGetSvgWidthOnResize from '../../../hooks/useGetSvgWidthOnResize';
import HeatMapCustomLegend from '../../Charts/HeatMapCustomLegend';
import { listSkills } from '../../../queries/customQueries';
import { getSpecialtyLegends } from '../../Charts/getSpecialtyLegends';

const TemplateOverview = ({ allTasks, tasks }) => {
  const { templateType } = useParams();
  const [divRef, width, labelWidth, updateHeatMapWidth] =
    useGetSvgWidthOnResize();
  const { data: listSkillsData, loading: listSkillsDataLoading } =
    useQueryRecursive(gql(listSkills));
  const taskIds = tasks?.map((t) => t.taskId);
  const specialtySkillsData = listSkillsData?.listSkills?.items || [];

  useEffect(() => {
    // update heatmap label
    setTimeout(() => {
      updateHeatMapWidth();
    }, 3000);
  }, [updateHeatMapWidth]);

  if (listSkillsDataLoading) {
    return <FdProgress />;
  }

  const tasksSelected = allTasks?.filter((a) => taskIds.includes(a.id)) || [];
  const Tile = ({ value, description }) => (
    <Box p={2}>
      <SummaryTitle
        data={{ value, description }}
        titleVariant="subtitle2"
        subtitleVariant="h4"
        justifyContent="center"
        subtitleColor="primary"
      />
    </Box>
  );
  Tile.propTypes = {
    value: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  const assessmentChallengesTechniques =
    getAssessmentChallengesTechniques(tasksSelected);

  const assessmentChallengesTechnologies =
    getAssessmentChallengesTechnologies(tasksSelected);

  const skillsProficienciesByProficiency = _.groupBy(
    tasksSelected?.map((task) => ({
      proficiency: task.difficulty,
      skills: task?.skills,
    })),
    'proficiency',
  );

  const { specialtyLegends, skillsSorted } = getSpecialtyLegends({
    specialtySkills: specialtySkillsData,
  });

  const skillsProficienciesData = getSkillsProficienciesMappedData(
    skillsProficienciesByProficiency,
    skillsSorted,
  );

  const TechniquesTab = () => (
    <Box>
      <Box display="flex" alignItems="center">
        <FdTypography variant="body1">
          Techniques in the assessment
        </FdTypography>
        <FdTooltip title="Techniques refer to the specific methodologies or strategies required to resolve a challenge, such as Cipher Decryption, Image Analysis, etc.">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </FdTooltip>
      </Box>
      <FdTypography
        color="secondary"
        variant="captiontext1"
        style={{ marginBottom: '16px' }}
      >
        The bar segments represent the associated challenge that the technique
        belongs to. The number on the segment represents the point weightage for
        the technique in the challenge. You can Hover over the bar segments to
        see the name of the Challenge.
      </FdTypography>
      <Box height="450px">
        <BarNivo
          data={assessmentChallengesTechniques}
          keys={tasksSelected?.map((t) => t?.name)}
          indexBy="technique"
          enableLabel={false}
          colors={['#90CAF9']}
          layout="horizontal"
          borderWidth={3}
          borderColor="#fff"
          tooltip={(d) => (
            <Box
              style={{
                backgroundColor: '#777',
                color: '#fff',
                padding: '4px 8px',
                fontSize: '12px',
              }}
            >
              {d.id}
            </Box>
          )}
          labelTextColor="#000"
        />
      </Box>
    </Box>
  );

  const TechnologiesTab = () => (
    <Box>
      <Box display="flex" alignItems="center">
        <FdTypography variant="body1">
          Technologies in the assessment
        </FdTypography>
        <FdTooltip title="Technologies include the tools or environments incorporated within the challenge. They can be the environment itself, such as Windows, Linux, or Docker, or tools utilized within those environments, such as Splunk, Nessus, Python, etc. These technologies form a crucial part of the task, offering resources to navigate and solve the challenge effectively.">
          <IconButton size="small" style={{ marginLeft: '5px' }}>
            <InfoOutlinedIcon />
          </IconButton>
        </FdTooltip>
      </Box>
      <FdTypography
        color="secondary"
        variant="captiontext1"
        style={{ marginBottom: '16px' }}
      >
        The bar segments represent the associated challenge that the technology
        belongs to. The number on the segment represents the point weightage for
        the technology in the challenge. You can Hover over the bar segments to
        see the name of the Challenge.
      </FdTypography>
      <Box height="450px">
        <BarNivo
          data={assessmentChallengesTechnologies}
          keys={tasksSelected.map((t) => t.name)}
          indexBy="technology"
          enableLabel={false}
          colors={['#90CAF9']}
          layout="horizontal"
          borderWidth={3}
          borderColor="#fff"
          tooltip={(d) => (
            <Box
              style={{
                backgroundColor: '#777',
                color: '#fff',
                padding: '4px 8px',
                fontSize: '12px',
              }}
            >
              {d.id}
            </Box>
          )}
          labelTextColor="#000"
        />
      </Box>
    </Box>
  );

  return (
    <Box mt={2}>
      <FdAccordion
        summary={() => (
          <Box>
            <FdTypography variant="h4">
              {`${capitalize(templateType)} Overview`}
            </FdTypography>
            <Box mt={1}>
              <FdTypography variant="body1" color="secondary">
                Expand to get a glimpse of the diversity of challenges based on
                Proficiency, Specialty and type.
              </FdTypography>
            </Box>
          </Box>
        )}
        content={() => (
          <Box display="flex">
            <Box display="flex" flexDirection="column" width="70%">
              <FdTypography variant="h4">
                What does this assessment cover?
              </FdTypography>
              <FdTypography
                variant="body2"
                color="secondary"
                style={{ margin: '16px 0' }}
              >
                This heatmap shows the skills that are being tested in this
                assessment in relation to the proficiency level. The X-Axis
                shows the skills and Y-axis represents the Proficiency level .
                Together, the green boxes show which skill of what proficiency
                has been covered in this assessment. Hover over the green boxes
                to see these details. You can also hover over the skills
                abbreviations on x-axis to see the skill name.
              </FdTypography>
              <Box style={{ height: '410px' }}>
                <Box ref={divRef} height="100%">
                  <HeatmapNivo
                    customTopLegend={
                      <Box display="flex" alignItems="center">
                        <FdTypography variant="captiontext1">
                          Profiency Levels
                        </FdTypography>

                        {Object.keys(PROFICIENCY).map((proficiency) => (
                          <Box p={1} display="flex" alignItems="center">
                            <FdTypography variant="captiontext1">{` ${PROFICIENCY[proficiency].level}:`}</FdTypography>
                            <Box
                              bgcolor={`${PROFICIENCY[proficiency].color}`}
                              className="rounded h-4 ml-1 px-1.5"
                            >
                              <FdTypography variant="captiontext1">
                                {proficiency}
                              </FdTypography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    }
                    customBottomLegend={
                      <HeatMapCustomLegend
                        width={width}
                        labelWidth={labelWidth}
                        specialtyLegends={specialtyLegends}
                      />
                    }
                    data={
                      skillsProficienciesData?.sort((a, b) => b.id - a.id) || []
                    }
                    yAxisLabel="Proficiency Levels"
                    tooltip={({ cell }) => {
                      return (
                        <Box
                          style={{
                            backgroundColor: '#777',
                            color: '#fff',
                            padding: '4px 8px',
                            fontSize: '12px',
                          }}
                        >
                          <FdTypography variant="captiontext2">
                            {cell.data?.skill}
                          </FdTypography>
                          <FdTypography variant="captiontext2">
                            {` Proficiency: ${cell.data?.proficiency}`}
                          </FdTypography>
                        </Box>
                      );
                    }}
                    color="#607094"
                  />
                </Box>
              </Box>
            </Box>
            <Box width="30%">
              <FdTab
                label={[
                  {
                    label: `Techniques(${assessmentChallengesTechniques?.length})`,
                    index: 0,
                    data: <TechniquesTab />,
                  },
                  {
                    label: `Technologies(${assessmentChallengesTechnologies?.length})`,
                    index: 1,
                    data: <TechnologiesTab />,
                  },
                ]}
              />
            </Box>
          </Box>
        )}
      />
    </Box>
  );
};

TemplateOverview.propTypes = {
  allTasks: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({}).isRequired).isRequired,
};

export default TemplateOverview;
