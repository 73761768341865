import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import {
  FdTable,
  FdSkeleton,
  FdButton,
  FdTypography,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import { gql, useMutation } from '@apollo/client';
import EditCustomerSkill from './EditCustomerSkill';
import {
  warningToastMessage,
  successToastMessage,
} from '../../../shared/utils/toast';
import {
  updateCustomerSkill,
  updateCustomerSkillFdSKill,
  deleteCustomerSkill,
  deleteCustomerSkillFdSKill,
  createCustomerSkillFdSKill,
  deleteCustomerSkillWorkrole,
} from '../../../graphql/mutations';
import DeleteModal from './DeleteModal';
import { duplicateName } from '../../../shared/utils/duplicateName';

const CustomerSkillTable = ({
  customerSkillData,
  customerSkillsFdSkills,
  customerSkillsWorkroles,
  fdSkills,
  refetchQueries,
  loading,
}) => {
  const [customerSkillModal, setCustomerSkillModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  // Update Mutataions
  const [updateCustomerSkillMutation, { loading: updateCustomerSkillLoading }] =
    useMutation(gql(updateCustomerSkill));
  const [
    updateCustomerSkillFdSKillMutation,
    { loading: updateCustomerSkillFdSKillLoading },
  ] = useMutation(gql(updateCustomerSkillFdSKill));

  const [
    createCustomerSkillFdSKillMutation,
    { loading: createCustomerSkillFdSKillLoading },
  ] = useMutation(gql(createCustomerSkillFdSKill));

  // Delete Mutataions
  const [deleteCustomerSkillMutation, { loading: deleteCustomerSkillLoading }] =
    useMutation(gql(deleteCustomerSkill));
  const [
    deleteCustomerSkillFdSKillMutation,
    { loading: deleteCustomerSkillFdSKillLoading },
  ] = useMutation(gql(deleteCustomerSkillFdSKill));
  const [
    deleteCustomerSkillWorkroleMutation,
    { loading: deleteCustomerSkillWorkroleLoading },
  ] = useMutation(gql(deleteCustomerSkillWorkrole));

  const columns = [
    { field: 'name', width: 450, headerName: 'Customer Skill Name' },
    {
      field: 'fdSkill',
      width: 450,
      headerName: 'Mapped FifthDomain Skill(s)',
      renderCell: (params) => {
        const fdSkillData = params?.value?.map(({ skill }) => skill);
        return (
          <FdSkeleton loading={loading} height={24} width={73}>
            <FdTooltip
              position="right-end"
              title={
                <Box m={2}>
                  <FdTypography>Mapped FifthDomain Skill(s):</FdTypography>
                  {fdSkillData?.map((i) => (
                    <ul key={i?.id} style={{ listStyleType: 'disc' }}>
                      <li>{`${i?.name} (${i?.alias}) `}</li>
                    </ul>
                  ))}
                </Box>
              }
            >
              <IconButton>
                <FdTypography variant="body2">
                  {`${fdSkillData[0]?.name} (${fdSkillData[0]?.alias}) ${
                    fdSkillData?.length > 1
                      ? `+ ${fdSkillData?.length - 1} More`
                      : ''
                  }`}
                </FdTypography>
              </IconButton>
            </FdTooltip>
          </FdSkeleton>
        );
      },
    },
    {
      field: 'workRoles',
      width: 450,
      headerName: 'Number of Work Roles ',
      renderCell: (params) => params?.value?.length,
    },
  ];

  const rows =
    customerSkillData?.map((item) => {
      const fdSkillDetail = customerSkillsFdSkills
        .filter((skill) => skill?.customerSkillId === item?.id)
        ?.map((i) => ({
          ...i,
          customerSkillsFdSkills: i?.id,
          id: i?.skill?.id,
          name: i?.skill?.name,
        }));
      return {
        ...item,
        fdSkill: fdSkillDetail,
        workRoles: customerSkillsWorkroles?.filter(
          (workRole) => workRole?.customerSkillId === item?.id,
        ),
      };
    }) || [];
  const actions = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
      CustomElement: ({ rowData }) => {
        return (
          <Box className="flex justify-center">
            <FdButton
              variant="tertiary"
              onClick={() => {
                setCustomerSkillModal(rowData);
              }}
            >
              Edit
            </FdButton>
            <FdButton
              variant="tertiary"
              onClick={() => {
                setDeleteModal(rowData);
              }}
            >
              DELETE
            </FdButton>
          </Box>
        );
      },
      width: 180,
    },
  ];

  return (
    <FdSkeleton loading={loading} height={480}>
      <Box height="450px" bgcolor="#fff">
        <FdTable
          toolbarSettings={{
            title: '',
            filterButton: true,
            searchBox: true,
          }}
          rows={rows}
          actions={actions}
          columns={columns}
          tablePageSize={10}
          gridId="work-role-list-org"
        />
        <EditCustomerSkill
          loading={
            updateCustomerSkillLoading ||
            deleteCustomerSkillFdSKillLoading ||
            updateCustomerSkillFdSKillLoading ||
            createCustomerSkillFdSKillLoading
          }
          fdSkills={fdSkills}
          openModal={customerSkillModal}
          onDismiss={() => {
            setCustomerSkillModal(false);
            warningToastMessage('Customer skill not updated');
          }}
          onConfirm={async (_customerSkills) => {
            const existingFdSKills = customerSkillModal?.fdSkill;
            const deleteItems = existingFdSKills?.filter(
              (item) =>
                !_customerSkills?.fdSkill
                  ?.map((i) => i?.id)
                  ?.includes(item?.id),
            );
            const addItems = _customerSkills?.fdSkill?.filter(
              (item) =>
                !existingFdSKills?.map((i) => i?.id)?.includes(item?.id),
            );
            const updateItems = existingFdSKills.map((skill) => ({
              ...skill,
              ..._customerSkills?.fdSkill?.find((wr) => wr?.id === skill?.id),
            }));
            const previousValue = customerSkillModal?.name?.toLowerCase();
            if (previousValue !== _customerSkills?.name.toLowerCase()) {
              if (duplicateName(customerSkillData, _customerSkills?.name)) {
                warningToastMessage(
                  'Customer Skill Name already exists in this organisation. Please enter a unique name.',
                );
                return;
              }
            }

            await updateCustomerSkillMutation({
              variables: {
                input: {
                  id: _customerSkills?.id,
                  name: _customerSkills?.name,
                },
              },
            });
            if (addItems?.length > 0) {
              await Promise.all(
                addItems?.map((i) => {
                  return createCustomerSkillFdSKillMutation({
                    variables: {
                      input: {
                        customerSkillId: _customerSkills?.id,
                        skillId: i?.id,
                      },
                    },
                  });
                }),
              );
            }
            if (updateItems?.length > 0) {
              await Promise.all(
                updateItems?.map((i) => {
                  return updateCustomerSkillFdSKillMutation({
                    variables: {
                      input: {
                        id: i?.customerSkillsFdSkills,
                        skillId: i?.skill?.id,
                      },
                    },
                  });
                }),
              );
            }
            if (deleteItems?.length > 0) {
              await Promise.all(
                deleteItems?.map((i) => {
                  return deleteCustomerSkillFdSKillMutation({
                    variables: {
                      input: {
                        id: i?.customerSkillsFdSkills,
                      },
                    },
                  });
                }),
              );
            }
            refetchQueries();
            successToastMessage('Customer skill updated!');
            setCustomerSkillModal(false);
          }}
        />
        <DeleteModal
          loading={
            deleteCustomerSkillLoading ||
            deleteCustomerSkillFdSKillLoading ||
            deleteCustomerSkillWorkroleLoading
          }
          openModal={deleteModal}
          onDismiss={async (_customerSkills) => {
            await deleteCustomerSkillMutation({
              variables: {
                input: {
                  id: _customerSkills?.id,
                },
              },
            });
            await Promise.all(
              _customerSkills?.fdSkill?.map(async (_item) => {
                await deleteCustomerSkillFdSKillMutation({
                  variables: {
                    input: {
                      id: _item?.customerSkillsFdSkills,
                    },
                  },
                });
              }),
            );
            if (_customerSkills?.workRoles?.length > 0) {
              await Promise.all(
                _customerSkills?.workRoles?.map(async (_item) => {
                  await deleteCustomerSkillWorkroleMutation({
                    variables: {
                      input: {
                        id: _item.id,
                      },
                    },
                  });
                }),
              );
            }
            refetchQueries();
            setDeleteModal(false);
          }}
          onConfirm={() => {
            setDeleteModal(false);
          }}
        />
      </Box>
    </FdSkeleton>
  );
};

CustomerSkillTable.propTypes = {
  customerSkillData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  customerSkillsFdSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  customerSkillsWorkroles: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,

  refetchQueries: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  fdSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default CustomerSkillTable;
