import React, { useState } from 'react';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdButton,
  FdTypography,
  useQueryRecursive,
} from '@fifthdomain/fe-shared';
import AddWorkRoleImage from '../../shared/images/add-work-role.svg';
import {
  warningToastMessage,
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import {
  listCustomerWorkRolesByOrgId,
  listCustomerSkillsByOrgId,
  listCustomerSkillFdSkills,
  listCustomerSkillsWorkrolesByOrgId,
  listFdSkills,
} from '../../graphql/queries';
import {
  createCustomerSkill,
  createCustomerSkillFdSKill,
  createCustomerWorkrole,
  createCustomerSkillWorkrole,
} from '../../graphql/mutations';
import WorkRoleTable from './WorkRoles/WorkRoleTable';
import CustomerSkillTable from './WorkRoles/CustomerSkillTable';
import AddWorkRole from './WorkRoles/AddWorkRole';
import AddCustomerSkill from './WorkRoles/AddCustomerSKill';
import { duplicateName } from '../../shared/utils/duplicateName';
import { initialValues } from '../../validation-schemas/WorkRole/workRole';

const OrgWorkRoles = ({ orgId }) => {
  const [workRoleModal, setWorkRoleModal] = useState(false);
  const [customerSkillModal, setCustomerSkillModal] = useState(false);

  const {
    data: customerWorkRolesData,
    loading: customerWorkRolesLoading,
    refetch: refetchcustomerWorkRoles,
  } = useQueryRecursive(gql(listCustomerWorkRolesByOrgId), {
    variables: {
      orgId,
      limit: 500,
    },
    skip: !orgId,
  });

  const listWorkRolesByOrdId =
    customerWorkRolesData?.listCustomerWorkRolesByOrgId?.items?.map((item) => ({
      ...item,
    })) || [];

  const {
    data: customerSkillsData,
    loading: customerSkillsLoading,
    refetch: refetchcustomerSkills,
  } = useQueryRecursive(gql(listCustomerSkillsByOrgId), {
    variables: {
      filter: { orgId: { eq: orgId } },
      limit: 500,
    },
    skip: !orgId,
  });

  const listCustomerSkillsByOrdId =
    customerSkillsData?.listCustomerSkills?.items || [];

  const {
    data: listCustomerSkillFdSkillsData,
    loading: listCustomerSkillFdSkillsLoading,
    refetch: refetchcustomerSkillsFdSkills,
  } = useQueryRecursive(gql(listCustomerSkillFdSkills), {
    variables: {
      limit: 1000,
    },
    staleTime: { seconds: 0 },
    skip: !orgId,
  });
  const listCustomerSkillsFdSkills =
    listCustomerSkillFdSkillsData?.listCustomerSkillFdSkills?.items || [];

  const {
    data: listCustomerSkillWorkrolesData,
    loading: listCustomerSkillWorkrolesLoading,
    refetch: refetchcustomerSkillsWorkroles,
  } = useQueryRecursive(gql(listCustomerSkillsWorkrolesByOrgId), {
    variables: {
      orgId,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
    skip: !orgId,
  });
  const listCustomerSkillsWorkroles =
    listCustomerSkillWorkrolesData?.listCustomerSkillsWorkrolesByOrgId?.items ||
    [];

  const { data: listSkillsData } = useQueryRecursive(gql(listFdSkills));

  const fdSkills =
    listSkillsData?.listSkills?.items?.map((i) => ({
      ...i,
      name: `${i?.name} (${i?.alias})`,
    })) || [];

  // Create Mutation
  const [createCustomerSkillMutation, { loading: createCustomerSkillLoading }] =
    useMutation(gql(createCustomerSkill));
  const [
    createCustomerSkillFdSKillMutation,
    { loading: createCustomerSkillFdSKillLoading },
  ] = useMutation(gql(createCustomerSkillFdSKill));
  const [
    createCustomerWorkroleMutation,
    { loading: createCustomerWorkroleLoading },
  ] = useMutation(gql(createCustomerWorkrole));
  const [
    createCustomerSkillWorkroleMutation,
    { loading: createCustomerSkillWorkroleLaoding },
  ] = useMutation(gql(createCustomerSkillWorkrole));

  const refetchQueriesCustomerSkills = () => {
    refetchcustomerSkills();
    refetchcustomerSkillsFdSkills();
  };

  const refetchQueriesCustomerWorkRoles = () => {
    refetchcustomerWorkRoles();
    refetchcustomerSkillsWorkroles();
  };

  return (
    <>
      <FdCard
        variant="outlined"
        heading={
          <Box display="flex" justifyContent="space-between">
            <FdTypography variant="h3">
              Work Roles in this Organisation
            </FdTypography>
            <FdButton
              size="large"
              disabled={!listCustomerSkillsByOrdId?.length}
              onClick={() => setWorkRoleModal(true)}
            >
              ADD WORK ROLE
            </FdButton>
          </Box>
        }
        subHeading="The table below contains a list of all work roles that are currently present within this organisation. Affiliated users within this organisation will be able to be assigned any of the work roles in the table below. Click ‘Add Work Role’ to add work roles to this organisation. You must have added at least one customer skill in this organisation to begin adding work roles. "
      >
        {listWorkRolesByOrdId?.length ? (
          <WorkRoleTable
            workRoleData={listWorkRolesByOrdId}
            customerSkillsWorkroles={listCustomerSkillsWorkroles}
            customerSkillsFdSkills={listCustomerSkillsFdSkills}
            customerSkillData={listCustomerSkillsByOrdId}
            refetchQueries={refetchQueriesCustomerWorkRoles}
            loading={
              customerWorkRolesLoading || listCustomerSkillWorkrolesLoading
            }
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box my={3}>
              <img src={AddWorkRoleImage} alt="add-work-role" />
            </Box>
            <FdTypography variant="captiontext1" color="secondary">
              This organisation doesn’t have any work roles yet.
            </FdTypography>
            <Box mt={1}>
              <FdTypography variant="captiontext1" color="secondary">
                Work roles will populate here once added.
              </FdTypography>
            </Box>
          </Box>
        )}
        <AddWorkRole
          loading={
            createCustomerWorkroleLoading || createCustomerSkillWorkroleLaoding
          }
          openModal={workRoleModal}
          customerSkillData={listCustomerSkillsByOrdId}
          onDismiss={() => {
            setWorkRoleModal(false);
            warningToastMessage('Work role not added');
          }}
          onConfirm={async (_workRoleData, reset) => {
            if (duplicateName(listWorkRolesByOrdId, _workRoleData?.name)) {
              warningToastMessage(
                'Work Role Name already exists in this organisation. Please enter a unique Work Role Name.',
              );
              return;
            }
            await createCustomerWorkroleMutation({
              variables: {
                input: {
                  orgId,
                  name: _workRoleData?.name?.trim(),
                },
              },
              onCompleted: async (_data) => {
                const wId = _data?.createCustomerWorkrole?.id;
                await Promise.all(
                  _workRoleData?.workRoleSkills?.map(async (item) => {
                    await createCustomerSkillWorkroleMutation({
                      variables: {
                        input: {
                          customerSkillId: item?.id,
                          customerWorkroleId: wId,
                          orgId,
                          difficulty: item?.difficulty,
                        },
                      },
                    });
                  }),
                );
                successToastMessage('Work role added!');
                setWorkRoleModal(false);
                reset(initialValues);
                refetchQueriesCustomerWorkRoles();
              },
            });
          }}
        />
      </FdCard>
      <FdCard
        variant="outlined"
        heading={
          <Box display="flex" justifyContent="space-between">
            <FdTypography variant="h3">
              Customer Skill to FifthDomain Skill Mappings
            </FdTypography>
            <FdButton size="large" onClick={() => setCustomerSkillModal(true)}>
              add customer skill(s)
            </FdButton>
          </Box>
        }
        subHeading="All customer skills in this organisation (as well as the FifthDomain skills they are mapped to) are listed below. Each customer skill can be mapped to one FifthDomain skill at any time. "
      >
        {listCustomerSkillsByOrdId?.length ? (
          <CustomerSkillTable
            customerSkillData={listCustomerSkillsByOrdId}
            customerSkillsFdSkills={listCustomerSkillsFdSkills}
            customerSkillsWorkroles={listCustomerSkillsWorkroles}
            fdSkills={fdSkills}
            refetchQueries={refetchQueriesCustomerSkills}
            loading={listCustomerSkillFdSkillsLoading || customerSkillsLoading}
          />
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box my={3}>
              <img src={AddWorkRoleImage} alt="add-work-role" />
            </Box>
            <FdTypography variant="captiontext1" color="secondary">
              This organisation doesn’t have any customer skills yet.
            </FdTypography>
            <Box mt={1}>
              <FdTypography variant="captiontext1" color="secondary">
                Customer skills will populate here once added.
              </FdTypography>
            </Box>
          </Box>
        )}
        <AddCustomerSkill
          loading={
            createCustomerSkillFdSKillLoading || createCustomerSkillLoading
          }
          fdSkills={fdSkills}
          openModal={customerSkillModal}
          onDismiss={() => {
            setCustomerSkillModal(false);
            warningToastMessage('Customer skill(s) not added');
          }}
          onConfirm={async (_customerSkills, reset) => {
            const skillName = _customerSkills?.addCustomerSkills?.map((item) =>
              item?.customerSkillName?.toLowerCase(),
            );
            const _duplicateName = listCustomerSkillsByOrdId
              ?.map((item) => item?.name?.toLowerCase())
              .some((i) => skillName.includes(i));
            if (_duplicateName) {
              warningToastMessage(
                'Customer Skill Name already exists in this organisation. Please enter a unique name.',
              );
              return;
            }
            await Promise.all(
              _customerSkills?.addCustomerSkills?.map((cs) => {
                return createCustomerSkillMutation({
                  variables: {
                    input: {
                      orgId,
                      name: cs?.customerSkillName?.trim(),
                    },
                  },
                  onCompleted: async (_data) => {
                    const id = _data?.createCustomerSkill?.id;
                    await Promise.all(
                      cs?.fdSkill?.map(async (item) => {
                        await createCustomerSkillFdSKillMutation({
                          variables: {
                            input: {
                              customerSkillId: id,
                              skillId: item?.id,
                            },
                          },
                        });
                      }),
                    );
                  },
                  onError: ({ graphQLErrors }) => {
                    errorToastMessage(graphQLErrors[0]?.message);
                  },
                });
              }),
            );
            successToastMessage('Customer skill(s) added!');
            setCustomerSkillModal(false);
            reset();
            refetchQueriesCustomerSkills();
          }}
        />
      </FdCard>
    </>
  );
};

OrgWorkRoles.propTypes = {
  orgId: PropTypes.string,
};
OrgWorkRoles.defaultProps = {
  orgId: undefined,
};

export default OrgWorkRoles;
