import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdTypography, FdModal, FdAlert } from '@fifthdomain/fe-shared';

const DeleteModal = ({
  workRole,
  loading,
  openModal,
  onConfirm,
  onDismiss,
}) => {
  return (
    <FdModal
      size="md"
      title={`Delete ${workRole ? 'Work Role' : 'Customer Skill'}?`}
      description={
        <>
          <FdTypography variant="body1" color="secondary">
            Are you sure you want to delete this
            {workRole ? ' work role ' : ' customer skill '}
            from this organisation?
          </FdTypography>
          <Box my={2}>
            <FdTypography variant="body1" color="secondary">
              {workRole
                ? 'Deleting this work role will unassign it from all users currently holding this role. These users will no longer have an assigned work role.'
                : 'Deleting this customer skill means it cannot be added to any work roles within this organisation. Any existing work roles that include this customer skill will have it removed from them.'}
            </FdTypography>
          </Box>
          <FdTypography variant="body1" color="secondary">
            {workRole
              ? 'Please consider these effects carefully before proceeding to delete this work role. This action cannot be undone.'
              : 'This action cannot be undone.'}
          </FdTypography>
          <Box my={3}>
            <FdAlert
              alertTitle="Important: "
              variant="error"
              message={
                <Box>
                  <ul style={{ listStyle: 'disc' }}>
                    <li>
                      <FdTypography variant="body2">
                        {workRole
                          ? 'Deleting this work role may impact the relevance of existing training activities, as well as any training activities currently being generated for affected users.'
                          : 'If deleting this customer skill causes a work role to have 0 customer skills, users already assigned to that work role will retain it. However, they will not have any target skills to fulfil. If a manager overlays the affected work role to a user Cortex, no required skills will be displayed.'}
                      </FdTypography>
                    </li>
                    <li>
                      <FdTypography variant="body2">
                        {workRole
                          ? 'Any users who currently assigned the work role you are deleting will no longer have a target state for their skills. This will impact the skills development planning of their managers.'
                          : 'Affected work roles with 0 customer skills will not be assignable to additional users. Only users already assigned these roles will retain them. If a different work role is assigned and saved for these users, the affected work role will no longer be available in the dropdown for future assignments.'}
                      </FdTypography>
                    </li>
                  </ul>
                </Box>
              }
            />
          </Box>
        </>
      }
      confirm="CANCEL"
      dismiss={
        loading ? (
          'Loading'
        ) : (
          <Box style={{ color: 'rgb(198, 40, 40)' }}>DELETE</Box>
        )
      }
      disableDismiss={loading}
      open={openModal}
      onConfirm={async () => {
        onConfirm();
      }}
      onDismiss={() => {
        onDismiss(openModal);
      }}
      data-cy="open-modal"
    />
  );
};

DeleteModal.propTypes = {
  workRole: PropTypes.bool,
  loading: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
};

DeleteModal.defaultProps = {
  workRole: false,
};

export default DeleteModal;
