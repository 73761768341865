import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { FdTable, FdModal, FdChip } from '@fifthdomain/fe-shared';
import { warningToastMessage } from '../../shared/utils/toast';
import InviteUsers from './InviteUsers';
import { getCommaSeparatedPlusSuffix } from '../../shared/utils/stringUtils';
import { MANAGER_PERMISSIONS } from '../../constants';

const UsersTable = ({ loading, rows, onEditUser }) => {
  const [userEmail, setUserEmail] = useState(undefined);
  const [userPermissions, setUserPermissions] = useState([]);
  const [accountOwner, setAccountOwner] = useState(false);

  const columns = [
    { field: 'name', width: 350, headerName: 'Name' },
    {
      field: 'affiliatedStatus',
      width: 200,
      headerName: 'Affiliation Status',
      valueGetter: (params) => params.value,
      renderCell: (params) =>
        params.row.affiliatedStatus === 'Confirmed' ? (
          <Box
            className="flex items-center gap-x-1"
            style={{ color: 'rgba(46, 125, 50, 1)' }}
          >
            <DoneIcon style={{ fontSize: 14 }} />
            {params.row.affiliatedStatus}
          </Box>
        ) : (
          params.row.affiliatedStatus
        ),
    },
    { field: 'email', width: 450, headerName: 'Email Address' },
    {
      field: 'registrationType',
      width: 200,
      headerName: 'Registration Status',
      renderCell: (params) => (
        <FdChip
          color={
            params?.row?.registrationType === 'Registered'
              ? 'success'
              : 'default'
          }
          size="small"
          label={params?.row?.registrationType}
        />
      ),
    },
    {
      field: 'permissions',
      width: 200,
      headerName: 'Permissions',
      valueGetter: (params) => params.value?.join(', '),
      renderCell: (params) =>
        getCommaSeparatedPlusSuffix(
          params.row.permissions?.map((p) => MANAGER_PERMISSIONS[p]),
        ),
    },
    { field: 'affiliatedDate', width: 150, headerName: 'Date of Affiliation' },
  ];
  const actions = [
    {
      label: 'Edit',
      show: true,
      onClick: (row) => {
        const orgAdmin = row?.permissions?.includes('ORG_ADMIN');
        setUserEmail(row?.email);
        setUserPermissions(row?.permissions);
        setAccountOwner(orgAdmin);
      },
    },
  ];

  return (
    <Box height="465px" bgcolor="#fff">
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={rows}
        columns={columns}
        tablePageSize={10}
        actions={actions}
        gridId="org-list-users"
      />

      <FdModal
        size="md"
        title={userEmail}
        confirm="Save"
        disableConfirm={loading}
        dismiss="CANCEL"
        open={userEmail}
        onConfirm={async () => {
          let result = [];
          result = userPermissions?.filter((u) => u !== 'ORG_ADMIN');
          if (accountOwner) {
            result?.push('ORG_ADMIN');
          }
          await onEditUser(userEmail, result);
        }}
        onDismiss={() => {
          setAccountOwner(false);
          setUserEmail(false);
          warningToastMessage('No changes made to the User(s)');
        }}
      >
        <InviteUsers
          userPermissions={userPermissions}
          setUserPermissions={setUserPermissions}
          accountOwner={accountOwner}
          setAccountOwner={setAccountOwner}
        />
      </FdModal>
    </Box>
  );
};

UsersTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onEditUser: PropTypes.func,
  loading: PropTypes.bool.isRequired,
};
UsersTable.defaultProps = {
  onEditUser: undefined,
};

export default UsersTable;
