import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { alpha } from '@material-ui/core';
import {
  FdChips,
  FdChip,
  FdCard,
  FdAlert,
  FdHighlightChip,
} from '@fifthdomain/fe-shared';
import { getTagColor } from '../../../shared/utils/tagUtils';
import { sortByDateField } from '../../../shared/utils/dateUtils';
import { capitalize } from '../../../shared/utils/stringUtils';
import { PROFICIENCY } from '../../../constants';
import { TemplateChallenges } from '../../../components/Template/View';

const Challenges = ({ templateMode, templateEditButton, showEditButton }) => {
  const {
    formState: { errors },
  } = useFormContext();

  const columns = [
    {
      field: 'name',
      flex: 1,
      headerName: 'Challenge Name',
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: (params) => capitalize(params?.value),
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Specialty',
      renderCell: (params) => <FdHighlightChip text={params?.value} />,
    },
    {
      field: 'difficulty',
      flex: 1,
      headerName: 'Proficiency',
      renderCell: (params) => {
        return (
          <FdChip
            label={params?.value}
            rounded={false}
            size="small"
            style={{
              backgroundColor: alpha(PROFICIENCY[params?.value]?.color, 0.25),
              color: PROFICIENCY[params?.value]?.color,
              fontWeight: 'bold',
            }}
          />
        );
      },
    },
    {
      field: 'recommendedPoints',
      width: 100,
      headerName: 'Points',
      type: 'number',
    },
    {
      field: 'tags',
      flex: 1,
      headerName: 'Tags',
      headerAlign: 'center',
      align: 'center',
      valueGetter: (params) =>
        params.row?.tags?.length > 0
          ? [...(params.row?.tags || [])].map((t) => t.Tag?.name)?.join(' ')
          : '',
      renderCell: (params) => {
        const _data = params.row?.tags
          ?.sort(sortByDateField('updatedAt', 'desc'))
          .map((t) => ({
            label: t.Tag?.name,
            color: getTagColor(t.Tag?.color),
          }));
        return _data?.length > 0 ? (
          <FdChips data={_data} numberOfChipsDisplayed={1} caption="More" />
        ) : (
          <Box width="100%" textAlign="center">
            -
          </Box>
        );
      },
    },
  ];

  return (
    <FdCard
      variant="outlined"
      heading={showEditButton ? 'Challenges' : 'Select Challenges'}
      subHeading={
        !showEditButton &&
        'Select challenges below to include in this training template. '
      }
    >
      <Box my={2} height="650px" width="100%">
        {errors?.tasks && (
          <Box my={2}>
            <FdAlert
              variant="error"
              message="Please select at least one challenge to proceed."
            />
          </Box>
        )}
        <TemplateChallenges
          trainingTemplate
          columns={columns}
          templateEditButton={templateEditButton}
          templateMode={templateMode}
        />
      </Box>
    </FdCard>
  );
};

Challenges.defaultProps = {
  templateMode: '',
  showEditButton: false,
};

Challenges.propTypes = {
  templateMode: PropTypes.string,
  templateEditButton: PropTypes.bool.isRequired,
  showEditButton: PropTypes.bool,
};

export default Challenges;
