import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useAuthSession } from '@fifthdomain/fe-shared';
import { USER_PERMISSION_TYPE } from '../constants';
import { userHasPermission } from '../shared/utils/getUsersPermissions';
import AppPageWrapperProvider from '../providers/AppPageWrapperProvider';

const OrganisationRoute = ({ hideSideBar, component: Component, ...rest }) => {
  const { user } = useAuthSession();

  const fdAdminPermission =
    (user && userHasPermission(user, USER_PERMISSION_TYPE.FD_ADMIN)) || false;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (user && !fdAdminPermission) {
          return <Redirect to="/" />;
        }

        if (user && fdAdminPermission) {
          return (
            <AppPageWrapperProvider hideSideBar={hideSideBar}>
              <Component {...rest} {...props} />
            </AppPageWrapperProvider>
          );
        }

        return null;
      }}
    />
  );
};

OrganisationRoute.defaultProps = {
  hideSideBar: false,
};

OrganisationRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  hideSideBar: PropTypes.bool,
};

export default OrganisationRoute;
