import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useSnapshot,
  globalStore,
  useAuthSession,
} from '@fifthdomain/fe-shared';
import AppPageWrapperProvider from '../providers/AppPageWrapperProvider';

const ContentCatalogueRoute = ({
  hideSideBar,
  component: Component,
  ...rest
}) => {
  const { user } = useAuthSession();
  const globalSnap = useSnapshot(globalStore);
  const type = globalSnap.userType;

  if (!type) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        // only ADMINS are allowed to view content lease catalogue
        if (user && type === 'ADMIN') {
          return (
            <AppPageWrapperProvider hideSideBar={hideSideBar}>
              <Component {...rest} {...props} />
            </AppPageWrapperProvider>
          );
        }
        return <Redirect to="/" />;
      }}
    />
  );
};

ContentCatalogueRoute.defaultProps = {
  hideSideBar: false,
};

ContentCatalogueRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
  hideSideBar: PropTypes.bool,
};

export default ContentCatalogueRoute;
