import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import { FdTable } from '@fifthdomain/fe-shared';

const OrgTable = ({ rows, actions }) => {
  const columns = [
    { field: 'name', width: 450, headerName: 'Name' },
    {
      field: 'members',
      width: 450,
      headerName: 'No. of Admins/Managers registered',
    },
    {
      field: 'products',
      width: 450,
      headerName: 'Assigned spaces',
      valueGetter: (params) => params?.row?.products,
      renderCell: (params) => (
        <Box display="flex" alignItems="center">
          {params?.value?.find((item) => item?.productName === 'Assessor') && (
            <Box display="flex" pr={2}>
              <AssignmentIcon />
            </Box>
          )}
          {params?.value?.find(
            (item) => item?.productName === 'Competitions',
          ) && (
            <Box display="flex" pr={2}>
              <EmojiEventsIcon />
            </Box>
          )}
          {params?.value?.find((item) => item?.productName === 'Labs') && (
            <Box display="flex" pr={2}>
              <WbIncandescentIcon
                style={{
                  transform: 'rotateX(180deg)',
                  marginBottom: '5px',
                }}
              />
            </Box>
          )}
        </Box>
      ),
    },
  ];

  return (
    <Box height="715px" bgcolor="#fff">
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={rows}
        actions={actions}
        columns={columns}
        tablePageSize={10}
        gridId="org-list-orgs"
      />
    </Box>
  );
};

OrgTable.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

OrgTable.defaultProps = {
  actions: [],
};

export default OrgTable;
