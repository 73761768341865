/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getSystemTime = /* GraphQL */ `
  query GetSystemTime {
    getSystemTime
  }
`;

export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        assessments {
          items {
            assessmentId
          }
        }
        labId
        lab {
          id
          name
          vms {
            items {
              id
            }
          }
          createdAt
          updatedAt
        }
        modulePart {
          labId
        }
        name
        type
        id
        files {
          items {
            id
            name
            type
            url
            size
          }
        }
        category
        summary
        difficulty
        specialtyId
        specialty {
          name
          skills {
            items {
              id
              name
            }
          }
        }
        skills {
          items {
            techniqueTags {
              items {
                techniqueTag {
                  name
                }
              }
            }
            skill {
              name
            }
          }
        }
        technologyTags {
          items {
            technologyTag {
              name
            }
          }
        }
        public
        competencies {
          items {
            competencyId
            area {
              areaName
            }
          }
        }
        org {
          name
        }
        orgs {
          items {
            org {
              name
              id
            }
          }
        }
        estimatedSolveTime
        recommendedPoints
        description
        solution
        answer
        hints {
          items {
            text
            id
          }
        }
      }
      nextToken
    }
  }
`;

export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        url
        name
        size
        type
        file {
          bucket
          key
          region
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHint = /* GraphQL */ `
  query GetHint($id: ID!) {
    getHint(id: $id) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const listHints = /* GraphQL */ `
  query ListHints(
    $filter: ModelHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAssessment = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessmentAssessmentId
      groupId
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        userId
        assessment {
          id
          orgId
          name
          participantEventType
        }
        status
      }
      nextToken
    }
  }
`;
export const listCoursesByOrgId = /* GraphQL */ `
  query ListCoursesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoursesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        status
        name
        availability
        courseUsers {
          items {
            userId
          }
        }
      }
      nextToken
    }
  }
`;

export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      assessments {
        items {
          taskId
          assessment {
            id
            name
            startDateTime
            creator {
              org {
                id
                name
              }
              orgId
            }
          }
        }
      }
    }
  }
`;

export const getTaskAttempt = /* GraphQL */ `
  query GetTaskAttempt($id: ID!) {
    getTaskAttempt(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const listTaskAttemptAggregatesByStatus = /* GraphQL */ `
  query ListTaskAttemptAggregatesByStatus(
    $status: TASK_ATTEMPT_STATUS!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByStatus(
      status: $status
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        teamId
        assessmentId
        taskId
        levels
        status
        points
        userAssessmentId
        attempts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTaskAttempts = /* GraphQL */ `
  query ListTaskAttempts(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        createdAt
        updatedAt
        taskAttemptTaskId
      }
      nextToken
    }
  }
`;
export const getTaskOpened = /* GraphQL */ `
  query GetTaskOpened($id: ID!) {
    getTaskOpened(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const listTaskOpeneds = /* GraphQL */ `
  query ListTaskOpeneds(
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpeneds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskOpenedUserAssessmentId
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      cyberExperience
      highestEducation
      highestEducationOther
      cyberTraining
      formalTraining
      formalTrainingOther
      selfDirectedTraining {
        hours
      }
      workingIndustry
      workingIndustryOther
      createdAt
      updatedAt
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
      members(filter: { type: { ne: "PARTICIPANT" } }) {
        items {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          task {
            modulePart {
              labId
            }
            name
            id
            category
            difficulty
            files {
              items {
                id
                name
                url
              }
            }
            competencies {
              items {
                competencyId
                area {
                  areaName
                }
              }
            }
            org {
              name
            }
            orgs {
              items {
                org {
                  name
                  id
                }
              }
            }
            estimatedSolveTime
            recommendedPoints
            description
            solution
            answer
            hints {
              items {
                text
                id
              }
            }
          }

          id
          taskId
          orgId
          createdAt
          updatedAt
        }
        nextToken
      }
      products {
        items {
          product {
            name
          }
          orgId
          productId
          createdAt
          updatedAt
        }
        nextToken
      }
      urls {
        items {
          id
          key
          theme
        }
      }
      maxInvitees
      remainingInvitees
      pricingTier
      themeId
      theme {
        id
        name
        special
        createdAt
        updatedAt
      }
      specialThemes {
        items {
          id
          orgId
          themeId
          theme {
            name
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;

export const listOrgs = /* GraphQL */ `
  query ListOrgs(
    $filter: ModelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        updatedAt
        id
        name
        tasks {
          items {
            task {
              id
              name
              type
            }
          }
        }
        members(filter: { type: { ne: "PARTICIPANT" } }) {
          items {
            id
            name
            type
          }
          nextToken
        }
        products {
          items {
            productId
            product {
              id
              name
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getUserConsent = /* GraphQL */ `
  query GetUserConsent($id: ID!) {
    getUserConsent(id: $id) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const listUserConsents = /* GraphQL */ `
  query ListUserConsents(
    $filter: ModelUserConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        consentedOn
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConsent = /* GraphQL */ `
  query GetConsent($id: ID!) {
    getConsent(id: $id) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const listConsents = /* GraphQL */ `
  query ListConsents(
    $filter: ModelConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupUser = /* GraphQL */ `
  query GetGroupUser($id: ID!) {
    getGroupUser(id: $id) {
      id
      groupId
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroupUsers = /* GraphQL */ `
  query ListGroupUsers(
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuiz = /* GraphQL */ `
  query GetQuiz($id: ID!) {
    getQuiz(id: $id) {
      id
      orgId
      name
      ownerId
      description
      duration
      multipleAttempts
      showResults
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      questions {
        items {
          id
          quizId
          type
          name
          point
          hasOptions
          caseSensitive
          whiteSpaceSensitive
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuizzes = /* GraphQL */ `
  query ListQuizzes(
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizzes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        ownerId
        description
        duration
        multipleAttempts
        showResults
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      quizId
      type
      name
      point
      hasOptions
      caseSensitive
      whiteSpaceSensitive
      options {
        items {
          questionId
          questionOptionType
          optionName
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      answers {
        items {
          questionId
          answerOptionType
          answer
          orderNumber
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      competencies {
        items {
          id
          competencyID
          questionID
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionOption = /* GraphQL */ `
  query GetQuestionOption($id: ID!) {
    getQuestionOption(id: $id) {
      questionId
      questionOptionType
      optionName
      id
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionOptions = /* GraphQL */ `
  query ListQuestionOptions(
    $filter: ModelQuestionOptionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionOptions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        questionId
        questionOptionType
        optionName
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestionAnswer = /* GraphQL */ `
  query GetQuestionAnswer($id: ID!) {
    getQuestionAnswer(id: $id) {
      questionId
      answerOptionType
      answer
      orderNumber
      id
      createdAt
      updatedAt
    }
  }
`;
export const listQuestionAnswers = /* GraphQL */ `
  query ListQuestionAnswers(
    $filter: ModelQuestionAnswerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionAnswers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        questionId
        answerOptionType
        answer
        orderNumber
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByOrg = /* GraphQL */ `
  query ListAssessmentsByOrg(
    $orgId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByParticipantEventType = /* GraphQL */ `
  query ListAssessmentsByParticipantEventType(
    $orgId: String!
    $participantEventType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByParticipantEventType(
      orgId: $orgId
      participantEventType: $participantEventType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTasksByUserId = /* GraphQL */ `
  query ListTasksByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByAssessmentId = /* GraphQL */ `
  query ListUsersByAssessmentId(
    $userAssessmentAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByAssessmentId(
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByOrgId = /* GraphQL */ `
  query ListUsersByOrgId(
    $orgId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrgId(
      orgId: $orgId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOnboardingsByUserId = /* GraphQL */ `
  query ListOnboardingsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardingsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByOrg = /* GraphQL */ `
  query ListInvitedUsersByOrg(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        groupId
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByAssessmentId = /* GraphQL */ `
  query ListInvitedUsersByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByGroupId = /* GraphQL */ `
  query ListInvitedUsersByGroupId(
    $groupId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByGroupId(
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        groupId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByGroupId = /* GraphQL */ `
  query ListUsersByGroupId(
    $groupId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByGroupId(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByUserId = /* GraphQL */ `
  query ListGroupsByUserId(
    $userId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByUserId(
      userId: $userId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByGroupId = /* GraphQL */ `
  query ListAssessmentsByGroupId(
    $groupId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByGroupId(
      groupId: $groupId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByAssessmenId = /* GraphQL */ `
  query ListGroupsByAssessmenId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByAssessmenId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuizesByOrgId = /* GraphQL */ `
  query ListQuizesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuizFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuizesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        ownerId
        description
        duration
        multipleAttempts
        showResults
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        questions {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listQuestionsByQuizId = /* GraphQL */ `
  query ListQuestionsByQuizId(
    $quizId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuestionsByQuizId(
      quizId: $quizId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        quizId
        type
        name
        point
        hasOptions
        caseSensitive
        whiteSpaceSensitive
        options {
          nextToken
        }
        answers {
          nextToken
        }
        competencies {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listGroupsByOrgId = /* GraphQL */ `
  query ListGroupsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        users {
          items {
            userId
            id
          }
          nextToken
        }
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUrls = /* GraphQL */ `
  query ListUrls(
    $filter: ModelUrlFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUrls(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        theme
        url
        key
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listThemes = /* GraphQL */ `
  query ListThemes(
    $filter: ModelThemeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThemes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        special
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listThemesByOrgId = /* GraphQL */ `
  query ListThemesByOrgId(
    $orgId: ID!
    $themeId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelThemeOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listThemesByOrgId(
      orgId: $orgId
      themeId: $themeId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        org {
          id
          name
          alias
          maxInvitees
          remainingInvitees
          themeId
          createdAt
          updatedAt
        }
        themeId
        theme {
          id
          name
          special
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTasksByMode = /* GraphQL */ `
  query ListTasksByMode(
    $public: BOOLEAN_FIELD_TYPE!
    $orgId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByMode(
      public: $public
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        category
        description
        summary
        estimatedSolveTime
        recommendedPoints
        modulePart {
          labId
        }
        lab {
          vms {
            items {
              id
            }
          }
        }
        competencies {
          items {
            area {
              areaName
              category {
                id
              }
            }
            workRole {
              workRole
            }
          }
        }
        difficulty
        specialtyId
        specialty {
          name
          skills {
            items {
              id
              name
            }
          }
        }
        skills {
          items {
            skillId
            techniqueTags {
              items {
                techniqueTag {
                  name
                }
              }
            }
          }
        }
        technologyTags {
          items {
            technologyTag {
              name
            }
          }
        }
        type
        orgs {
          items {
            org {
              name
            }
          }
        }
        files {
          items {
            id
            name
          }
        }
      }
      nextToken
    }
  }
`;

export const listTemplates = /* GraphQL */ `
  query ListTemplates(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        teamBased
        hours
        minutes
        tasks {
          items {
            task {
              difficulty
              name
              id
            }
          }
        }
        story
        startDateTime
        endDateTime
        preMessage
        postMessage
        participantEventType
        creator {
          id
          email
          name
        }
        createdBy
        hideScoreBoard
        status
        createdAt
        updatedAt
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskOrgs = /* GraphQL */ `
  query ListTaskOrgs($limit: Int, $nextToken: String, $orgId: ID) {
    listTaskOrgs(
      filter: { orgId: { eq: $orgId } }
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        orgId
        task {
          id
          name
          description
          type
          externalId
          labId
          lab {
            id
            name
            vms {
              items {
                id
              }
            }
            createdAt
            updatedAt
          }
          orgId
          org {
            id
            name
          }
          category
          specialtyId
          specialty {
            name
            skills {
              items {
                id
                name
              }
            }
          }
          skills {
            items {
              skillId
              techniqueTags {
                items {
                  techniqueTag {
                    name
                  }
                }
              }
            }
          }
          technologyTags {
            items {
              technologyTag {
                name
              }
            }
          }
          difficulty
          competencies {
            items {
              area {
                areaName
              }
            }
          }
          hints {
            items {
              id
              taskId
              text
            }
          }
          tags(filter: { orgId: { eq: $orgId } }) {
            items {
              Tag {
                color
                id
                name
              }
              updatedAt
            }
          }
          files {
            items {
              id
              name
              type
              url
              size
            }
          }
          recommendedPoints
          estimatedSolveTime
          answer
          userId
          user {
            id
            name
            org {
              id
              name
            }
          }
          modulePartId
          modulePart {
            name
            labId
            expiry
            lab {
              name
              status
            }
          }
          status
          createdAt
          updatedAt
        }
        org {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getTemplate = /* GraphQL */ `
  query GetTemplate($id: ID!) {
    getTemplate(id: $id) {
      id
      name
      description
      teamBased
      hours
      minutes
      story
      preMessage
      postMessage
      participantEventType
      creator {
        id
        email
        name
      }
      createdBy
      startDateTime
      endDateTime
      hideScoreBoard
      status
      tasks {
        items {
          id
          templateId
          taskId
          createdAt
          updatedAt
          __typename
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
  }
`;

export const listAffliationRequestsByOrgId = /* GraphQL */ `
  query ListAffliationRequestsByOrgId(
    $orgId: ID!
    $status: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAffliationRequestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationRequestsByOrgId(
      orgId: $orgId
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        creatorId
        email
        permissions
        status
        createdAt
        updatedAt
        user {
          id
          name
          alias
          permissions
        }
      }
      nextToken
      __typename
    }
  }
`;

export const listCustomerWorkRolesByOrgId = /* GraphQL */ `
  query ListCustomerWorkRolesByOrgId(
    $orgId: ID!
    $filter: ModelCustomerWorkroleFilterInput
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    listCustomerWorkRolesByOrgId(
      orgId: $orgId
      filter: $filter
      limit: $limit
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      items {
        id
        name
        alias
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCustomerSkillsByOrgId = /* GraphQL */ `
  query listCustomerSkills(
    $filter: ModelCustomerSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCustomerSkillFdSkills = /* GraphQL */ `
  query ListCustomerSkillFdSkills(
    $filter: ModelCustomerSkillFdSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerSkillFdSkills(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        skillId
        skill {
          id
          name
          alias
        }
        customerSkillId
      }
      nextToken
    }
  }
`;

export const listCustomerSkillsWorkrolesByOrgId = /* GraphQL */ `
  query ListCustomerSkillsWorkrolesByOrgId(
    $orgId: ID!
    $filter: ModelCustomerSkillWorkroleFilterInput
    $limit: Int
    $sortDirection: ModelSortDirection
    $nextToken: String
  ) {
    listCustomerSkillsWorkrolesByOrgId(
      orgId: $orgId
      filter: $filter
      limit: $limit
      sortDirection: $sortDirection
      nextToken: $nextToken
    ) {
      items {
        id
        customerWorkroleId
        difficulty
        customerSkillId
        orgId
        customerSkill {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const listFdSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        alias
        specialty {
          name
          id
        }
      }
      nextToken
    }
  }
`;

export const listTemplatesByParticipantEventType = /* GraphQL */ `
  query ListTemplatesByParticipantEventType(
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
    $participantEventType: PARTICIPANT_EVENT_TYPE!
    $sortDirection: ModelSortDirection
    $status: ModelStringKeyConditionInput
  ) {
    listTemplatesByParticipantEventType(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      participantEventType: $participantEventType
      sortDirection: $sortDirection
      status: $status
    ) {
      items {
        id
        name
        participantEventType
      }
      nextToken
      __typename
    }
  }
`;
