import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { FdTypography } from '@fifthdomain/fe-shared';

const useStyles = makeStyles()((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.background.default,
    color: theme.palette.typography.primary,
  },
}));

const LevelTooltip = ({ data, totalTasks }) => {
  const { classes } = useStyles();
  const { label, value } = data;

  return (
    <Box
      display="flex"
      alignItems="center"
      style={{
        borderRadius: 4,
      }}
      p={1}
      width={188}
      className={classes.tooltip}
    >
      <Box className="flex flex-col items-center">
        <FdTypography variant="subtitle1" style={{ textAlign: 'center' }}>
          {label}
        </FdTypography>
        <Box className="flex items-center gap-x-1">
          <FdTypography variant="subtitle2">{value}</FdTypography>
          <FdTypography variant="captiontext2">no of challenge(s)</FdTypography>
        </Box>
        <Box className="flex items-center gap-x-1">
          <FdTypography variant="subtitle2">
            {`${Math.round((value / totalTasks) * 100)}%`}
          </FdTypography>
          <FdTypography variant="captiontext2">
            of all challenge(s)
          </FdTypography>
        </Box>
      </Box>
    </Box>
  );
};

LevelTooltip.propTypes = {
  data: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.number,
  }).isRequired,
  totalTasks: PropTypes.number.isRequired,
};

export default LevelTooltip;
