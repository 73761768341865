import React from 'react';
import { Box } from '@mui/material';
import { gql } from '@apollo/client';
import { format } from 'date-fns';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {
  BasePage,
  FdTypography,
  FdLoadingSpinner,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTab,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { listTemplates } from '../graphql/queries';
import { TemplateTable, TrainingTemplateTable } from '../components/Template';
import { productStyle } from '../shared/utils/layout';
import { sortedByCreatedDate } from '../shared/utils/dateUtils';
import { getTemplateAvailabilityStatus } from '../shared/utils/templateUtils';

const ListTemplates = () => {
  const globalSnap = useSnapshot(globalStore);

  const {
    data: listTemplatesData,
    loading: listTemplatesLoading,
    refetch: refetchlistTemplates,
  } = useQueryRecursive(gql(listTemplates), {
    variables: {
      orgId: globalSnap.orgId,
    },
    skip: !globalSnap.orgId,
    staleTime: { seconds: 0 },
  });

  if (listTemplatesLoading) {
    return <FdLoadingSpinner />;
  }

  const eventsData =
    listTemplatesData?.listTemplates?.items?.map((template) => {
      return {
        ...template,
        creator: template?.creator?.name,
        proficiency: '',
        type: template?.teamBased ? 'Team' : 'Individual',
        availabilityStatus: getTemplateAvailabilityStatus(
          template?.startDateTime,
          template?.endDateTime,
        ),
        availability: `${format(new Date(template?.startDateTime), 'dd/MM/yyyy')} - ${format(new Date(template?.endDateTime), 'dd/MM/yyyy')}`,
        icon: (
          <Box style={productStyle(template?.participantEventType)}>
            {template?.participantEventType === 'ASSESSMENT' ? (
              <AssignmentIcon style={{ height: '18px' }} />
            ) : (
              <EmojiEventsIcon style={{ height: '18px' }} />
            )}
          </Box>
        ),
      };
    }) || [];

  const allTrainings =
    eventsData?.filter(
      (allCompetition) => allCompetition.participantEventType === 'FDTRAINING',
    ) || [];

  const allCompetitions = eventsData?.filter(
    (allCompetition) => allCompetition.participantEventType === 'COMPETITION',
  );

  const allAssessments = eventsData?.filter(
    (allCompetition) => allCompetition.participantEventType === 'ASSESSMENT',
  );

  const numberOfEvents =
    (allCompetitions.length > 0 ? 1 : 0) + (allAssessments.length > 0 ? 1 : 0);

  const tabLabel = [
    // {
    //   label: (
    //     <Box display="flex" alignItems="center">
    //       <FdTypography variant="body2">
    //         {`Training Templates (${allTrainings?.length})`}
    //       </FdTypography>
    //     </Box>
    //   ),
    //   path: '/allTrainings',
    //   index: 1,
    //   data: (
    //     <TrainingTemplateTable
    //       rows={sortedByCreatedDate([...allTrainings])}
    //       refetchTemplateData={refetchlistTemplates}
    //     />
    //   ),
    // },
    allCompetitions?.length && {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('COMPETITION')} mr={0.5}>
            <EmojiEventsIcon style={{ marginTop: '4px' }} />
          </Box>
          <FdTypography variant="body2">{`Competitions Templates (${allCompetitions?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allCompetitions',
      index: 2,
      data: (
        <TemplateTable
          rows={sortedByCreatedDate([...allCompetitions])}
          refetchTemplateData={refetchlistTemplates}
          templateType="competition"
        />
      ),
    },
    allAssessments?.length && {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('ASSESSMENT')} mr={0.5}>
            <AssignmentIcon style={{ marginTop: '4px' }} />
          </Box>
          <FdTypography variant="body2">{`Assessments Templates (${allAssessments?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allAssessments',
      index: 3,
      data: (
        <TemplateTable
          rows={sortedByCreatedDate([...allAssessments])}
          refetchTemplateData={refetchlistTemplates}
          templateType="assessment"
        />
      ),
    },
  ];

  return (
    <>
      <FdBreadcrumbHeader
        page={{ name: 'Template Library', type: 'TEMPLATE' }}
      />
      <BasePage
        data-cy="welcome-card"
        heading="Template Library"
        type="TEMPLATE"
      >
        <FdCard variant="outlined">
          {numberOfEvents > 0 && (
            <FdTab label={tabLabel?.filter((item) => item)} />
          )}
        </FdCard>
      </BasePage>
    </>
  );
};
export default ListTemplates;
