import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import {
  useWatch,
  Controller,
  useFormContext,
  useFormState,
} from 'react-hook-form';
import { FdCard, FdTextField, FdAlert } from '@fifthdomain/fe-shared';

const Duration = ({ heading }) => {
  const { control, trigger } = useFormContext();
  const { errors, touchedFields } = useFormState({
    control,
  });
  const watchHours = useWatch({
    control,
    name: 'hours',
  });
  const watchMinutes = useWatch({
    control,
    name: 'minutes',
  });

  // force trigger to validate, as react-hook-form sends old error state
  useEffect(() => {
    trigger('duration');
  }, [watchHours, trigger]);

  useEffect(() => {
    trigger('duration');
  }, [watchMinutes, trigger]);

  return (
    <FdCard
      variant="outlined"
      heading={heading || 'Duration'}
      subHeading="Select how much time you would like organisation participants to have to complete this assessment."
      preHeader={
        Object.keys(touchedFields).some((f) =>
          ['hours', 'minutes'].includes(f),
        ) &&
        (errors?.hours || errors?.minutes || errors?.duration) && (
          <Box mb={2}>
            <FdAlert
              alertTitle="Please enter a duration period"
              variant="error"
              message={
                <Box>
                  <Box>{errors?.hours?.message}</Box>
                  <Box>{errors?.minutes?.message}</Box>
                  <Box>{errors?.duration?.message}</Box>
                </Box>
              }
            />
          </Box>
        )
      }
      data-cy="duration-card"
    >
      <Box display="flex">
        <Controller
          control={control}
          name="hours"
          render={({ field: { ref, ...rest } }) => (
            <Box mt={1} mb={2} mr={2}>
              <FdTextField
                id="hours"
                label="Hours"
                required
                type="number"
                width="134px"
                inputRef={ref}
                data-cy="hours"
                {...rest}
              />
            </Box>
          )}
        />
        <Controller
          control={control}
          name="minutes"
          render={({ field: { ref, ...rest } }) => (
            <Box mt={1} mb={2}>
              <FdTextField
                id="minutes"
                label="Minutes"
                type="number"
                required
                width="134px"
                inputRef={ref}
                data-cy="minutes"
                {...rest}
              />
            </Box>
          )}
        />
      </Box>
    </FdCard>
  );
};

Duration.propTypes = {
  heading: PropTypes.string,
};

Duration.defaultProps = {
  heading: '',
};

export default Duration;
