import React, { useEffect } from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { setShowNav, ReactQueryProvider } from '@fifthdomain/fe-shared';
import { AuthProvider } from '@fifthdomain/auth';
import GraphQlClient from './shared/GraphQlClient';
import OrganisationRoute from './routes/OrganisationRoute';
import ContentCatalogueRoute from './routes/ContentCatalogueRoute';
import ManageOrgs from './pages/ManageOrgs';
import CreateOrg from './pages/CreateOrg';
import ViewOrg from './pages/ViewOrg';
import ContentCatalogue from './pages/ContentCatalogue';
import AppThemeProvider from './providers/AppThemeProvider';
import ContentLeaseCatalogue from './pages/ContentLeaseCatalogue';
import ListTemplates from './pages/ListTemplates';
import CreateTemplate from './pages/CreateTemplate';
import ViewTemplate from './pages/ViewTemplate';
import './organisation.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'organisation',
  seed: 'organisation',
});

const App = () => {
  useEffect(() => {
    setShowNav(true);
  });

  return (
    <GraphQlClient>
      <AuthProvider>
        <ReactQueryProvider>
          <AppThemeProvider>
            <StylesProvider generateClassName={generateClassName}>
              <>
                <ToastContainer />
                <BrowserRouter>
                  <Switch>
                    <OrganisationRoute
                      path="/org"
                      component={ManageOrgs}
                      hideSideBar
                      exact
                    />
                    <OrganisationRoute
                      path="/org/create"
                      component={CreateOrg}
                      hideSideBar
                    />
                    <OrganisationRoute
                      path="/org/view/:orgId"
                      component={ViewOrg}
                      hideSideBar
                    />
                    <OrganisationRoute
                      path="/org/content-library"
                      component={ContentCatalogue}
                      hideSideBar
                      exact
                    />
                    <OrganisationRoute
                      path="/org/template-library"
                      component={ListTemplates}
                      exact
                    />
                    <OrganisationRoute
                      path="/org/template-library/create/:templateType"
                      component={CreateTemplate}
                    />
                    <OrganisationRoute
                      path="/org/template-library/view/:templateType/:templateId"
                      component={ViewTemplate}
                    />
                    <ContentCatalogueRoute
                      path="/org/content-lease-catalogue"
                      component={ContentLeaseCatalogue}
                      hideSideBar
                      exact
                    />
                    <Redirect to="/" />
                  </Switch>
                </BrowserRouter>
              </>
            </StylesProvider>
          </AppThemeProvider>
        </ReactQueryProvider>
      </AuthProvider>
    </GraphQlClient>
  );
};

export default App;
