import React, { useState } from 'react';
import { Box } from '@mui/material';
import { gql, useMutation } from '@apollo/client';
import { addDays, format, isAfter } from 'date-fns';
import PropTypes from 'prop-types';
import {
  FdCard,
  FdButton,
  FdTypography,
  FdModal,
  useQueryRecursive,
  FdLoadingSpinner,
  toProperCase,
} from '@fifthdomain/fe-shared';
import AddUserImage from '../../shared/images/add-user.svg';
import { addUsers, updateRoleAndPermissions } from '../../graphql/mutations';
import { listAffliationRequestsByOrgId } from '../../graphql/queries';
import {
  warningToastMessage,
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import UsersTable from './UsersTable';
import { validEmail } from '../../shared/utils/emailUtil';
import InviteUsers from './InviteUsers';

const OrgUsers = ({ orgId }) => {
  const [showModal, setShowModal] = useState(false);
  const [emails, setEmails] = useState([]);
  const [error, setError] = useState(false);
  const [userPermissions, setUserPermissions] = useState([]);
  const [accountOwner, setAccountOwner] = useState(false);

  const {
    data: affiliatedRequestsData,
    loading: affiliatedRequestsLoading,
    refetch: refetchAffiliatedRequestsData,
  } = useQueryRecursive(gql(listAffliationRequestsByOrgId), {
    variables: {
      orgId,
      limit: 500,
    },
    skip: !orgId,
  });

  const allAffiliatedUsers =
    affiliatedRequestsData?.listAffliationRequestsByOrgId?.items
      ?.filter((u) => !['DISAFFILIATED', 'REVOKED'].includes(u?.status))
      ?.map((u) => {
        return {
          id: u?.id,
          name: u?.user?.name || '',
          email: u?.email,
          registrationType:
            u?.userId !== 'UNDEFINED' ? 'Registered' : 'Invited',
          permissions:
            u?.userId !== 'UNDEFINED'
              ? u?.user?.permissions?.map((p) => p)
              : u?.permissions?.map((p) => p),
          affiliatedStatus:
            u?.status === 'ACCEPTED'
              ? 'Confirmed'
              : u?.status === 'PENDING' &&
                  !isAfter(addDays(new Date(u?.updatedAt), 30), new Date())
                ? 'Expired'
                : toProperCase(u?.status),
          affiliatedDate:
            u?.status === 'ACCEPTED'
              ? format(new Date(u?.updatedAt), 'dd/MM/yyyy')
              : '',
        };
      }) || [];

  const [addUsersMutation, { loading: addUsersLoading }] = useMutation(
    gql(addUsers),
    {
      onCompleted: (_data) => {
        setShowModal(false);
        setEmails([]);
        setUserPermissions([]);
        successToastMessage('Success - affiliated user(s) invited!');
        refetchAffiliatedRequestsData();
      },
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message?.split('email')[1]);
      },
    },
  );

  const [
    updateRoleAndPermissionsMutation,
    { loading: updateRoleAndPermissionsLoading },
  ] = useMutation(gql(updateRoleAndPermissions), {
    onCompleted: (_data) => {
      setShowModal(false);
      successToastMessage('Success! User permissions updated');
      refetchAffiliatedRequestsData();
    },
    onError: ({ graphQLErrors }) => {
      errorToastMessage(graphQLErrors[0]?.message);
    },
  });

  if (affiliatedRequestsLoading) {
    return <FdLoadingSpinner />;
  }

  return (
    <FdCard variant="outlined">
      {allAffiliatedUsers.length ? (
        <Box>
          <Box display="flex" justifyContent="space-between" mb={2}>
            <FdTypography variant="h2">Affiliated Users</FdTypography>
            <FdButton variant="primary" onClick={() => setShowModal(true)}>
              Invite Affiliated User(s)
            </FdButton>
          </Box>
          <UsersTable
            loading={updateRoleAndPermissionsLoading}
            rows={allAffiliatedUsers}
            onEditUser={async (email, permissions) => {
              updateRoleAndPermissionsMutation({
                variables: {
                  emails: email,
                  permissions,
                },
              });
            }}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box>
            <FdTypography variant="h2" color="secondary">
              When you invite affiliated users to this organisation, they will
              appear here.
            </FdTypography>
          </Box>
          <Box my={2}>
            <img src={AddUserImage} alt="add-user" />
          </Box>
          <Box my={1}>
            <FdButton size="large" onClick={() => setShowModal(true)}>
              Invite Affiliated Users
            </FdButton>
          </Box>
        </Box>
      )}

      <FdModal
        size="md"
        title="Invite Affiliated Users"
        confirm={addUsersLoading ? 'Loading' : 'invite'}
        dismiss="CANCEL"
        disableConfirm={addUsersLoading}
        open={showModal}
        onConfirm={() => {
          if (emails.length < 1) {
            setError('Enter at least 1 email');
            return;
          }
          if (emails.some((email) => !validEmail(email))) {
            setError('One or more of the email addresses are invalid');
            return;
          }
          if (accountOwner) {
            userPermissions?.push('ORG_ADMIN');
          }
          addUsersMutation({
            variables: {
              emails,
              orgId,
              participantType: 'INTERNAL',
              affliationAction: 'NEW_REQUEST',
              permissions: userPermissions,
            },
          });
        }}
        onDismiss={() => {
          setShowModal(false);
          setError(false);
          setAccountOwner(false);
          setEmails([]);
          setUserPermissions([]);
          warningToastMessage('Affiliated user(s) not invited!');
        }}
      >
        <InviteUsers
          addUsers
          emails={emails}
          setEmails={setEmails}
          accountOwner={accountOwner}
          setAccountOwner={setAccountOwner}
          userPermissions={userPermissions}
          setUserPermissions={setUserPermissions}
          error={error}
        />
      </FdModal>
    </FdCard>
  );
};

OrgUsers.propTypes = {
  orgId: PropTypes.string,
};
OrgUsers.defaultProps = {
  orgId: undefined,
};

export default OrgUsers;
