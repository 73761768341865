import React, { useState } from 'react';
import shortid from 'shortid';
import PropTypes from 'prop-types';
import { useQuery, gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useParams } from 'react-router-dom';
import {
  FdButton,
  FdTypography,
  FdProgress,
  FdCard,
} from '@fifthdomain/fe-shared';
import {
  initialValues,
  validationSchema,
} from '../../validation-schemas/Organisation';
import { getOrg, listProducts, listThemes } from '../../graphql/queries';
import { OrgDetails, FdTextView, OrgThemes, OrgProducts } from './index';
import {
  updateOrg,
  createProductOrg,
  deleteProductOrg,
  createThemeOrg,
  deleteThemeOrg,
} from '../../graphql/mutations';
import {
  successToastMessage,
  warningToastMessage,
} from '../../shared/utils/toast';
import {
  createUpdateOrg,
  createUpdateProducts,
  createUpdateThemes,
  getProductName,
} from './orgUtils';
import { getPreSignedUrl } from '../../shared/utils/fileUtils';
import FileAttachment from '../Logo/FileAttachment';
import LogoUpload from '../Logo/LogoUpload';
import { capitalize } from '../../shared/utils/stringUtils';

const EditOrgDetail = () => {
  const [editDetails, setEditDetails] = useState(false);
  const [editThemes, setEditThemes] = useState(false);
  const [editAssignedProducts, setEditAssignedProducts] = useState(false);
  const [editLogo, setEditLogo] = useState(false);

  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, getValues, setValue, trigger } = reactHookFormMethods;
  const { orgId } = useParams();

  const [updateOrgMutation, { loading: updateOrgMutationLoading }] =
    useMutation(gql(updateOrg), {
      refetchQueries: ['GetOrg'],
      awaitRefetchQueries: true,
      onCompleted: () => {
        setEditDetails(false);
        if (editDetails) {
          successToastMessage('Success! Org updated');
        }
      },
    });
  const [createProductOrgMutation] = useMutation(gql(createProductOrg), {
    refetchQueries: ['GetOrg'],
    awaitRefetchQueries: true,
    onCompleted: (_data) =>
      successToastMessage(
        `Success! ${getProductName(
          _data.createProductOrg?.product?.name,
        )} added to the org.`,
      ),
  });
  const [deleteProductOrgMutation] = useMutation(gql(deleteProductOrg), {
    refetchQueries: ['GetOrg'],
    awaitRefetchQueries: true,
    onCompleted: (_data) =>
      successToastMessage(
        `${getProductName(
          _data.deleteProductOrg?.product?.name,
        )} removed from the org.`,
      ),
  });

  const [createThemeOrgMutation] = useMutation(gql(createThemeOrg), {
    refetchQueries: ['GetOrg'],
    awaitRefetchQueries: true,
  });
  const [deleteThemeOrgMutation] = useMutation(gql(deleteThemeOrg), {
    refetchQueries: ['GetOrg'],
    awaitRefetchQueries: true,
  });

  const { data: listProductsData, loading: listProductsLoading } = useQuery(
    gql(listProducts),
  );
  const allProducts = listProductsData?.listProducts?.items;

  const { data: listThemesData, loading: listThemesLoading } = useQuery(
    gql(listThemes),
  );

  const allThemes = listThemesData?.listThemes?.items;
  const defaultThemes = allThemes?.filter((theme) => theme.special === 'NO');
  const specialThemes = allThemes?.filter((theme) => theme.special === 'YES');

  const { data: orgData, loading: getOrgLoading } = useQuery(gql(getOrg), {
    variables: {
      id: orgId,
    },
    fetchPolicy: 'network-only',
    skip: !orgId,
    onCompleted: async (_data) => {
      const { name, products, maxInvitees, urls, pricingTier, theme } =
        _data.getOrg;

      const orgurls = urls?.items?.map(async ({ theme, key }) => ({
        theme,
        key,
        url: await getPreSignedUrl(key),
      }));

      const logos = await Promise.all(orgurls);
      // reset form values
      reset({
        orgId,
        orgName: name,
        products: products?.items?.map((item) => ({
          ...item,
          name: item.product?.name,
          id: item.productId,
          pId: shortid.generate(),
        })),
        assessorLimit: maxInvitees,
        removedProducts: [],
        existingProducts: products?.items?.map((item) => ({
          id: item.productId,
          name: item.product?.name,
          pId: shortid.generate(),
        })),
        logos: logos.map(({ key, theme, url }) => ({ key, theme, url })),
        pricingTier: pricingTier ? capitalize(pricingTier) : 'Custom',
        orgSelectedTheme: theme?.name || 'LIGHT',
      });
    },
  });

  const orgThemes = orgData?.getOrg?.specialThemes?.items?.map((item) => item);
  const orgThemesDB = specialThemes?.filter((obj1) =>
    orgThemes?.some((obj2) => obj1.id === obj2.themeId),
  );
  const allOrgThemes = defaultThemes?.concat(orgThemesDB);

  if (getOrgLoading || listProductsLoading || listThemesLoading) {
    return <FdProgress />;
  }

  const EditHeaderActions = ({ editMode }) => (
    <Box display="flex" alignItems="center">
      <FdButton
        size="small"
        variant="secondary"
        onClick={() => {
          if (editMode) {
            setValue('orgName', orgData?.getOrg?.name);
            setValue(
              'pricingTier',
              orgData?.getOrg?.pricingTier
                ? capitalize(orgData?.getOrg?.pricingTier)
                : 'Custom',
            );
            setEditDetails(false);
          }

          if (editAssignedProducts) {
            setValue(
              'products',
              orgData?.getOrg?.products?.items?.map((item) => item),
            );
            setEditAssignedProducts(false);
          }

          if (editThemes) {
            setEditThemes(false);
          }
          warningToastMessage('Changes to org not saved');
        }}
        data-cy="cancel-button"
      >
        Cancel
      </FdButton>
      <Box ml={1}>
        <FdButton
          size="small"
          onClick={async () => {
            if (editMode) {
              const result = await trigger(['orgName', 'pricingTier']);
              if (result) {
                const { orgName, pricingTier } = getValues();
                createUpdateOrg({
                  createOrgMutation: null,
                  updateOrgMutation,
                  orgValues: { orgName, orgId, pricingTier },
                });
              }
            }

            if (editAssignedProducts) {
              const result = await trigger(['assessorLimit']);
              if (!result) {
                return;
              }
              const values = getValues();
              const { products, assessorLimit, removedProducts } = values;
              const originalMaxInvitees = orgData?.getOrg?.maxInvitees;
              const originalRemainingInvitees =
                orgData?.getOrg?.remainingInvitees;

              const limitDifference = assessorLimit - originalMaxInvitees;

              const remainingInviteesUpdate =
                originalRemainingInvitees + limitDifference > 0
                  ? originalRemainingInvitees + limitDifference
                  : 0;
              // add/delete products + limit values
              const { productsRemoved, productsAdded } =
                await createUpdateProducts({
                  products,
                  createProductOrgMutation,
                  deleteProductOrgMutation,
                  orgId,
                  removedProducts,
                  updateOrgMutation,
                  assessorLimit,
                  remainingInvitees: remainingInviteesUpdate,
                });
              if (!productsRemoved.length && !productsAdded.length) {
                successToastMessage('Success! Org updated');
              }
              setEditAssignedProducts(false);
            }

            if (editThemes) {
              const orgSelectedTheme = getValues('orgSelectedTheme');
              const removedThemes = getValues('removedThemes');
              const addedThemes = getValues('addedThemes');

              await createUpdateThemes({
                createThemeOrgMutation,
                deleteThemeOrgMutation,
                updateOrgMutation,
                orgId,
                orgSelectedTheme: allThemes.find(
                  (_t) => _t.name === orgSelectedTheme,
                ),
                removedThemes,
                addedThemes,
              });

              successToastMessage('Success! Org updated');
              setEditThemes(false);
            }
          }}
          disabled={updateOrgMutationLoading}
          data-cy="save-button"
        >
          Save
        </FdButton>
      </Box>
    </Box>
  );
  EditHeaderActions.propTypes = {
    editMode: PropTypes.bool,
  };
  EditHeaderActions.defaultProps = {
    editMode: false,
  };

  const ViewHeaderActions = ({ setEditMode, heading, optional }) => (
    <Box display="flex" justifyContent="space-between">
      <FdTypography variant="h3">
        {`${heading} `}
        {optional && (
          <span style={{ color: 'rgba(0, 0, 0, 0.54)', fontSize: 19 }}>
            optional
          </span>
        )}
      </FdTypography>
      <Box>
        <FdButton variant="primary" size="small" onClick={setEditMode}>
          EDIT
        </FdButton>
      </Box>
    </Box>
  );

  ViewHeaderActions.propTypes = {
    setEditMode: PropTypes.func,
    heading: PropTypes.string.isRequired,
    optional: PropTypes.bool,
  };
  ViewHeaderActions.defaultProps = {
    setEditMode: () => {},
    optional: false,
  };

  return (
    <FormProvider {...reactHookFormMethods}>
      <form>
        {editDetails ? (
          <OrgDetails editMode={<EditHeaderActions editMode />} />
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <ViewHeaderActions
                setEditMode={() => setEditDetails(true)}
                heading="Details"
              />
            }
          >
            <Box mt={2}>
              <FdTextView label="Org Name" value={orgData?.getOrg?.name} />
              <Box mt={1}>
                <FdTextView
                  label="Pricing Tier"
                  value={getValues('pricingTier')}
                />
              </Box>
            </Box>
          </FdCard>
        )}
        {editThemes ? (
          <OrgThemes
            allThemes={allThemes}
            orgThemes={orgData?.getOrg?.specialThemes?.items?.map(
              (item) => item,
            )}
            editMode={<EditHeaderActions />}
          />
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <ViewHeaderActions
                setEditMode={() => setEditThemes(true)}
                heading="Assigned Themes"
                optional
              />
            }
          >
            <Box mt={2}>
              <FdTextView
                label="Special Themes"
                value={
                  orgData?.getOrg?.specialThemes?.items
                    ?.map((item) => {
                      return item?.theme?.name;
                    })
                    .toString() || 'None'
                }
              />
            </Box>
            <Box mt={2}>
              <FdTextView
                label="Default Theme"
                value={orgData?.getOrg?.theme?.name || 'None'}
              />
            </Box>
          </FdCard>
        )}
        {editLogo ? (
          <LogoUpload
            allThemes={allThemes}
            orgThemes={orgData?.getOrg?.specialThemes?.items?.map(
              (item) => item,
            )}
            editMode={editLogo}
            setEditLogo={() => setEditLogo(false)}
          />
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <ViewHeaderActions
                setEditMode={() => setEditLogo(true)}
                heading="Org Logos"
                optional
              />
            }
          >
            <Box mt={2}>
              {allOrgThemes?.map((theme) => {
                const logo = getValues('logos')?.find(
                  (orgLogo) => orgLogo.theme === theme.name,
                );
                const key = logo?.key?.split('/')[1];

                return (
                  <Box
                    display="flex"
                    flexDirection="column"
                    mb={2}
                    key={shortid.generate()}
                  >
                    <FdTypography variant="body1" fontWeight="medium">
                      {`${theme.name} Logo `}
                    </FdTypography>
                    {key ? (
                      <FileAttachment
                        logoKey={key}
                        url={logo?.url}
                        onClickAttachment={() => {}}
                      />
                    ) : (
                      <FdTypography variant="body1" color="secondary">
                        No logo Uploaded
                      </FdTypography>
                    )}
                  </Box>
                );
              })}
            </Box>
          </FdCard>
        )}

        {editAssignedProducts ? (
          <OrgProducts
            setValueProducts={setValue}
            allProducts={allProducts}
            maxInvitees={getValues()?.assessorLimit}
            editMode={<EditHeaderActions />}
          />
        ) : (
          <FdCard
            variant="outlined"
            heading={
              <ViewHeaderActions
                setEditMode={() => setEditAssignedProducts(true)}
                heading="Assigned Products"
              />
            }
          >
            <Box mt={2}>
              {getValues('products')?.map((item) => (
                <Box mb={2} key={shortid.generate()}>
                  <FdTypography variant="h4">
                    {item?.product?.name}
                    {item?.product?.name === 'Assessor' &&
                      getValues()?.assessorLimit > 0 && (
                        <FdTypography variant="subtitle1">
                          {`Max limit assigned: ${getValues()?.assessorLimit}`}
                        </FdTypography>
                      )}
                  </FdTypography>
                </Box>
              ))}
            </Box>
          </FdCard>
        )}
      </form>
    </FormProvider>
  );
};

export default EditOrgDetail;
