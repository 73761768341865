import React, { useState } from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { FdTable } from '@fifthdomain/fe-shared';
import ViewTaskDrawer from '../Task/ViewTaskDrawer';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';
import { getCommaSeparatedPlusSuffix } from '../../shared/utils/stringUtils';

const OrgContentCatalogue = ({ data, openDrawer, setOpenDrawer }) => {
  const [activeTask, setActiveTask] = useState();
  const { control } = useFormContext();

  const columns = [
    { field: 'name', flex: 2, width: 480, headerName: 'Name' },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge or task can be classified."
        />
      ),
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      unSort: true,
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      renderCell: (params) => getCommaSeparatedPlusSuffix(params.value),
    },
    { field: 'workRoles', flex: 1, headerName: 'NICE Work Roles' },
    {
      field: 'difficulty',
      flex: 1,
      headerName: 'Proficiency',
    },
    {
      field: 'type',
      width: 200,
      headerName: 'Type',
    },
  ];

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = data?.find((t) => t?.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  return (
    <Box>
      <Controller
        control={control}
        name="taskIds"
        render={({ field: { value, onChange } }) => (
          <Box mt={2} mb={2} height="750px" data-cy="tasks-table">
            <FdTable
              columnVisibilityModel={{
                difficulty: false,
              }}
              toolbarSettings={{
                searchBox: true,
                filterButton: true,
              }}
              selectionModel={value}
              onSelectionModelChange={onChange}
              selection
              disableSelectionOnClick
              actions={viewTaskActions}
              rows={data}
              columns={columns}
              pagination
              visibleSelection
              rowsPerPageOptions={[5, 10, 20]}
              tablePageSize={10}
              gridId="org-content-catalogues"
            />
          </Box>
        )}
      />
      {activeTask && openDrawer && (
        <ViewTaskDrawer
          activeTaskData={activeTask}
          openDrawer={openDrawer}
          openDrawerCallBack={setOpenDrawer}
          mainPageIds={['topnav', 'organisation']}
          leaseCatalogueView
        />
      )}
    </Box>
  );
};

OrgContentCatalogue.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  openDrawer: PropTypes.bool.isRequired,
  setOpenDrawer: PropTypes.func.isRequired,
};

OrgContentCatalogue.defaultProps = {
  data: [],
};

export default OrgContentCatalogue;
