import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FdTab, FdTypography, FdTooltip } from '@fifthdomain/fe-shared';
import { FdCatalogue } from '../Organisation';

const CatalogueTabs = ({ tasks, currentTasksData }) => {
  return (
    <FdTab
      label={[
        {
          customLabel: (
            <Box display="flex" alignItems="center">
              <FdTypography variant="subtitle2">Preview</FdTypography>
              <FdTooltip title="The Preview tab shows compositions of challenge profesional specialty, proficiency, and type in your organisation’s library if a transaction on currently selected challenges is made.">
                <span size="small" style={{ marginLeft: '10px' }}>
                  <InfoOutlinedIcon />
                </span>
              </FdTooltip>
            </Box>
          ),
          index: 0,
          data: <FdCatalogue allTasks={tasks} leaseCatalogue />,
        },
        {
          label: 'Current View',
          index: 1,
          data: <FdCatalogue allTasks={currentTasksData} leaseCatalogue />,
        },
      ]}
    />
  );
};

CatalogueTabs.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  currentTasksData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

export default CatalogueTabs;
