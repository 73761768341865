import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdCard, FdMarkdownEditor } from '@fifthdomain/fe-shared';

const PostMessage = ({ templateType, heading }) => {
  const { control } = useFormContext();
  return (
    <FdCard
      variant="outlined"
      heading={heading || `Post-${templateType} Message`}
      subHeading={`Enter a post-${templateType} message that will be displayed to all organisation participants after they have completed the ${templateType}. `}
    >
      <Box mt={0}>
        <Controller
          control={control}
          name="postMessage"
          render={({ field, fieldState: { error } }) => (
            <Box mb={2}>
              <FdMarkdownEditor
                id="postMessage"
                name="postMessage"
                markdown={field.value}
                setMarkdown={field.onChange}
                label="Message"
                required
                error={error}
                errorText={error && error.message}
                {...field}
              />
            </Box>
          )}
        />
      </Box>
    </FdCard>
  );
};

PostMessage.propTypes = {
  templateType: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  heading: PropTypes.string,
};

PostMessage.defaultProps = {
  heading: '',
};

export default PostMessage;
