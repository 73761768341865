import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton, useTheme } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FdTypography, FdTooltip } from '@fifthdomain/fe-shared';

const CatalogueTile = ({
  titleText,
  tooltipText,
  value,
  caption,
  emphasisValue,
}) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" alignItems="center" minHeight="46px">
        <FdTypography variant="subtitle2">{titleText}</FdTypography>
        {tooltipText && (
          <FdTooltip title={tooltipText}>
            <IconButton size="large">
              <InfoOutlinedIcon />
            </IconButton>
          </FdTooltip>
        )}
      </Box>
      <Box display="flex" alignItems="center">
        <FdTypography
          variant="h3"
          style={
            emphasisValue
              ? {
                  fontWeight: 'bold',
                  color: theme.palette.primary.main,
                }
              : {}
          }
        >
          {value}
        </FdTypography>
        {caption && (
          <Box ml={1}>
            <FdTypography variant="subtitle2">{caption}</FdTypography>
          </Box>
        )}
      </Box>
    </Box>
  );
};

CatalogueTile.propTypes = {
  titleText: PropTypes.string.isRequired,
  tooltipText: PropTypes.string,
  value: PropTypes.string.isRequired,
  caption: PropTypes.string,
  emphasisValue: PropTypes.bool,
};

CatalogueTile.defaultProps = {
  tooltipText: undefined,
  caption: undefined,
  emphasisValue: false,
};

export default CatalogueTile;
