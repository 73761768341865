import React from 'react';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import { FdTypography } from '@fifthdomain/fe-shared';

const SummaryTitle = ({
  data: { value, description, icon },
  titleVariant,
  subtitleVariant,
  justifyContent,
  subtitleColor,
}) => (
  <Box display="flex" justifyContent="center" alignItems="center">
    {icon && (
      <Box mr={2} data-cy="summary-icon">
        {icon}
      </Box>
    )}
    <Box display="flex" flexDirection="column">
      <Box
        display="flex"
        justifyContent={justifyContent}
        data-cy="summary-value"
      >
        <FdTypography variant={titleVariant}>{value}</FdTypography>
      </Box>
      {description && (
        <Box
          display="flex"
          justifyContent={justifyContent}
          data-cy="summary-desc"
          my={0.5}
          style={{ textAlign: 'center' }}
        >
          <FdTypography variant={subtitleVariant} color={subtitleColor}>
            {description}
          </FdTypography>
        </Box>
      )}
    </Box>
  </Box>
);

SummaryTitle.propTypes = {
  data: PropTypes.shape({
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.node,
    ]),
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    icon: PropTypes.element,
  }).isRequired,
  titleVariant: PropTypes.string,
  subtitleVariant: PropTypes.string,
  justifyContent: PropTypes.string,
  subtitleColor: PropTypes.string,
};

SummaryTitle.defaultProps = {
  titleVariant: 'h3',
  subtitleVariant: 'subtitle2',
  justifyContent: 'center',
  subtitleColor: 'secondary',
};

export default SummaryTitle;
