import React, { useState, useEffect } from 'react';
import {
  Box,
  Drawer,
  IconButton,
  Card,
  CardHeader,
  CardContent,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import shortid from 'shortid';
import CloseIcon from '@mui/icons-material/Close';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdTypography,
  FdMarkdownRender,
  FdButton,
  FdHighlightChip,
  FdAlert,
  FdChips,
  FdTooltip,
  FdFileAttachment,
} from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import ShowMoreText from 'react-show-more-text';
import setAppMarginRightByIds from '../../shared/utils/layout';
import { getDifficultyLevel } from '../../shared/utils/difficultyMapping';
import { PROFICIENCY_LEVELS } from '../../constants';

const drawerWidth = 400;

const useStyles = makeStyles()(() => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    '& .see-more': {
      color: '#1976D2',
      display: 'block',
      paddingTop: '0.5rem',
      textDecoration: 'none',
    },
  },
  drawerPaper: {
    width: drawerWidth,
  },
  description: {
    wordWrap: 'break-word',
  },
}));

const ViewTaskDrawer = ({
  activeTaskData,
  openDrawer,
  openDrawerCallBack,
  mainPageIds,
  leaseCatalogueView,
}) => {
  const [activeTask, setActiveTask] = useState(activeTaskData);
  const { classes } = useStyles();

  const ContentWithLabel = ({ label, content }) => (
    <Box display="flex" alignItems="center" mb={2}>
      <Box width="120px">
        <FdTypography variant="subtitle1" data-cy="task-drawer-label">
          {label}
        </FdTypography>
      </Box>
      {content}
    </Box>
  );
  ContentWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
  };

  const LabelWithTooltip = ({ label, tooltipText }) => (
    <Box display="flex" alignItems="center">
      <FdTypography variant="subtitle1">{label}</FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="large">
          <InfoOutlinedIcon />
        </IconButton>
      </FdTooltip>
    </Box>
  );
  LabelWithTooltip.propTypes = {
    label: PropTypes.string.isRequired,
    tooltipText: PropTypes.node.isRequired,
  };

  useEffect(() => {
    setActiveTask(activeTaskData);
  }, [activeTaskData]);

  useEffect(() => {
    setAppMarginRightByIds(mainPageIds, '400px');
  });
  const fileAttachments = activeTask?.files?.items?.map((f) => f?.name) || [];
  const filesNamesAttached =
    fileAttachments.length > 0 ? fileAttachments : ['None'];

  return (
    <Drawer
      variant="persistent"
      anchor="right"
      open={openDrawer}
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      data-cy="drawer"
    >
      <Card
        heading="Test"
        style={{
          overflow: 'auto',
          height: '100vh',
          marginBottom: '5px',
        }}
        data-cy="drawer-card"
      >
        <CardHeader
          action={
            <IconButton
              onClick={() => {
                setAppMarginRightByIds(mainPageIds, '0');
                openDrawerCallBack(false);
              }}
              size="large"
            >
              <CloseIcon style={{ fontSize: 28 }} />
            </IconButton>
          }
          title={activeTask?.name}
        />
        <CardContent>
          <Box mb={2}>
            {leaseCatalogueView && (
              <ContentWithLabel
                label="Type"
                content={
                  <FdTypography
                    color="secondary"
                    variant="body1"
                    data-cy="type"
                  >
                    {activeTask?.type}
                  </FdTypography>
                }
              />
            )}
            <ContentWithLabel
              label="Professional Specialty"
              content={<FdHighlightChip text={activeTask?.specialtyName} />}
            />
            <ContentWithLabel
              label="Proficiency"
              content={
                <FdHighlightChip
                  text={
                    activeTask?.difficulty > 5
                      ? getDifficultyLevel(activeTask?.difficulty)
                      : PROFICIENCY_LEVELS[activeTask?.difficulty]
                  }
                />
              }
            />
            <ContentWithLabel
              label="Points"
              content={<FdHighlightChip text={activeTask?.recommendedPoints} />}
            />
            <ContentWithLabel
              label="Est. Time to Solve"
              content={
                <FdHighlightChip
                  text={`${activeTask?.estimatedSolveTime} (min)`}
                />
              }
            />
            {activeTask?.attempts === 0 && (
              <Box mt={2} mb={3}>
                <FdHighlightChip text="Never Solved Before" />
              </Box>
            )}
            {!leaseCatalogueView && activeTask?.assigned?.length !== 0 && (
              <Box mb={2}>
                <FdTypography variant="subtitle1" data-cy="orgs-heading">
                  Historically assigned to following orgs
                </FdTypography>
                <FdTypography variant="body1" color="secondary">
                  {activeTask?.assigned?.map((item) => (
                    <ul key={shortid.generate()} data-cy="orgs">
                      <li>{item}</li>
                    </ul>
                  ))}
                </FdTypography>
              </Box>
            )}
            {activeTask?.description && !leaseCatalogueView && (
              <Box mb={2} className={classes.description}>
                <LabelWithTooltip
                  label="Description"
                  tooltipText="Description is viewable by participants for challenge instructions or other related details."
                />
                <FdTypography variant="body1" color="secondary">
                  <ShowMoreText
                    more="See more"
                    less="See less"
                    anchorClass="see-more"
                  >
                    <FdMarkdownRender markdown={activeTask?.description} />
                  </ShowMoreText>
                </FdTypography>
              </Box>
            )}

            {activeTask?.summary && (
              <Box mb={2} className={classes.description}>
                <LabelWithTooltip
                  label="Summary"
                  tooltipText="Summary is viewable by admins/managers for quick reference and overview of the challenge for  use-cases such as challenge curation for events, leasing challenges etc."
                />
                <FdTypography variant="body1" color="secondary">
                  <ShowMoreText
                    more="See more"
                    less="See less"
                    anchorClass="see-more"
                  >
                    <FdMarkdownRender markdown={activeTask?.summary} />
                  </ShowMoreText>
                </FdTypography>
              </Box>
            )}

            {activeTask?.skills?.length > 0 && (
              <Box mb={2}>
                <LabelWithTooltip
                  label="Skills"
                  tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
                />
                <FdTypography variant="body1" color="secondary">
                  {activeTask?.skills.map((item) => (
                    <ul key={shortid.generate()} data-cy="skills">
                      <li>{item}</li>
                    </ul>
                  ))}
                </FdTypography>
              </Box>
            )}
            {activeTask?.techniqueTags?.length > 0 && (
              <Box mb={2}>
                <LabelWithTooltip
                  label="Techniques"
                  tooltipText="Techniques refer to the specific methods or strategies required to resolve a challenge."
                />
                <FdChips
                  data={activeTask?.techniqueTags?.map((t) => ({
                    label: t,
                    color: 'default',
                  }))}
                />
              </Box>
            )}
            {activeTask?.technologyTags?.length > 0 && (
              <Box mb={2}>
                <LabelWithTooltip
                  label="Technologies"
                  tooltipText="Technologies include the environments (e.g., Windows, Linux, Docker) and tools (e.g., Splunk, Nessus, Python) incorporated within the challenge, crucial for its resolution."
                />
                <FdChips
                  data={activeTask?.technologyTags?.map((t) => ({
                    label: t,
                    color: 'default',
                  }))}
                />
              </Box>
            )}
            {leaseCatalogueView &&
              activeTask?.competencies?.items?.length !== 0 && (
                <Box mb={2}>
                  <FdTypography variant="subtitle1" data-cy="workrole-heading">
                    NICE Work Role
                  </FdTypography>
                  <FdTypography variant="body1" color="secondary">
                    {[
                      ...new Set(
                        activeTask?.competencies?.items?.map(
                          (item) => item?.workRole?.workRole,
                        ),
                      ),
                    ].map((item) => (
                      <ul key={shortid.generate()} data-cy="workRoles">
                        <li>{item}</li>
                      </ul>
                    ))}
                  </FdTypography>
                </Box>
              )}
            {!leaseCatalogueView && activeTask?.modulePart?.labId && (
              <Box my={3}>
                <FdTypography variant="subtitle1" data-cy="lab-heading">
                  Lab
                </FdTypography>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={1}
                  mb={2}
                >
                  <FdTypography variant="body1">
                    {activeTask?.modulePart?.lab?.name}
                  </FdTypography>
                  <FdButton
                    variant="secondary"
                    onClick={() =>
                      window.open(
                        `/labs/view/${activeTask?.modulePart?.labId}`,
                        '_blank',
                      )
                    }
                  >
                    View Lab
                  </FdButton>
                </Box>
              </Box>
            )}
            {!leaseCatalogueView && activeTask?.files?.items?.length !== 0 && (
              <Box mb={2}>
                <FdTypography
                  variant="subtitle1"
                  data-cy="attachments-subtitle"
                >
                  Attachments
                </FdTypography>
                {activeTask?.files?.items?.map((f) => (
                  <FdFileAttachment name={f.name} />
                ))}
              </Box>
            )}
            {leaseCatalogueView && (
              <Box mb={2}>
                <FdTypography variant="subtitle1" data-cy="file-attachments">
                  Attachments
                </FdTypography>
                <FdTypography variant="body1" color="secondary">
                  {filesNamesAttached.map((file) => (
                    <ul key={shortid.generate()}>
                      <li>{file}</li>
                    </ul>
                  ))}
                </FdTypography>
              </Box>
            )}
            {!leaseCatalogueView && activeTask?.hints?.items?.length !== 0 && (
              <Box mb={4}>
                <FdTypography variant="subtitle1" data-cy="hints-title">
                  Hints
                </FdTypography>
                <FdTypography variant="body1" color="secondary">
                  {activeTask?.hints?.items?.map((h) => (
                    <ul key={shortid.generate()} data-cy="hints">
                      <li>{h?.text}</li>
                    </ul>
                  ))}
                </FdTypography>
              </Box>
            )}
            {!leaseCatalogueView && (
              <ContentWithLabel
                label="Flag"
                content={
                  <FdTypography
                    color="secondary"
                    variant="body1"
                    data-cy="flag"
                  >
                    {activeTask?.answer}
                  </FdTypography>
                }
              />
            )}
            {leaseCatalogueView && (
              <FdAlert
                alertTitle="Lease to View Accompanying Assets"
                variant="info"
                customIcon={<ShoppingCartIcon />}
                message="The accompanying assets of this Challenge (e.g. solution walkthrough, flag, attachments, labs) will be viewable once this challenge is leased by your organisation. "
              />
            )}
          </Box>
        </CardContent>
      </Card>
    </Drawer>
  );
};

ViewTaskDrawer.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeTaskData: PropTypes.object.isRequired,
  openDrawer: PropTypes.bool.isRequired,
  openDrawerCallBack: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  mainPageIds: PropTypes.array,
  leaseCatalogueView: PropTypes.bool,
};

ViewTaskDrawer.defaultProps = {
  mainPageIds: [],
  leaseCatalogueView: false,
};

export default ViewTaskDrawer;
