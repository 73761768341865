import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useForm, FormProvider, Controller, useWatch } from 'react-hook-form';
import {
  FdTextField,
  FdModal,
  FdAutocomplete,
  FdTable,
  FdSelect,
  FdHighlightChip,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  initialValues,
  validationSchema,
} from '../../../validation-schemas/WorkRole/workRole';
import { PROFICIENCY } from '../../../constants';

const AddWorkRole = ({
  wRDetails,
  openModal,
  isEdit,
  onConfirm,
  onDismiss,
  customerSkillData,
  loading,
}) => {
  const reactHookFormMethods = useForm({
    defaultValues: isEdit ? wRDetails : initialValues,
    resolver: yupResolver(validationSchema), // validation schema
    mode: 'all',
  });
  const {
    control,
    getValues,
    trigger,
    reset,
    setValue,
    clearErrors,
    formState: { errors },
  } = reactHookFormMethods;

  const watchWorkRoleSkills = useWatch({
    control,
    name: 'workRoleSkills',
  });

  useEffect(() => {
    reset(wRDetails);
  }, [wRDetails, reset]);

  const proficiency = Object.entries(PROFICIENCY)?.map((i) => ({
    id: i[1]?.level,
    name: i[0],
    color: i[1]?.color,
  }));

  const errrorsMessage = errors?.workRoleSkills?.length;

  return (
    <FormProvider {...reactHookFormMethods}>
      <form style={{ width: '100%' }}>
        <FdModal
          size="lg"
          title={isEdit ? 'Edit Work Role' : 'Add Work Role'}
          confirm={loading ? 'Loading' : 'Save'}
          disableConfirm={loading}
          dismiss="CANCEL"
          open={openModal}
          onConfirm={async () => {
            const result = await trigger();
            // submit confirm with values
            if (result) {
              onConfirm(getValues(), reset);
            }
          }}
          onDismiss={() => {
            reset();
            onDismiss();
          }}
          data-cy="open-modal"
        >
          <Box display="flex" flexDirection="column" width="100%">
            <Controller
              control={control}
              name="name"
              render={({ field: { ref, ...rest }, fieldState: { error } }) => (
                <Box mt={1}>
                  <FdTextField
                    id="name"
                    label="Work Role Name"
                    placeholder="Enter Work Role Name"
                    required
                    fullWidth
                    error={error}
                    helperText={error?.message}
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              )}
            />
            <Box mt={3} mb={3}>
              <Controller
                control={control}
                name="workRoleSkills"
                render={({
                  field: { ref, ...rest },
                  fieldState: { error },
                }) => {
                  return (
                    <Box mb={2}>
                      <FdAutocomplete
                        id="workRoleSkills"
                        label="Required Customer Skills"
                        placeholder="Select Customer Skill(s)"
                        options={customerSkillData}
                        multiple
                        fullWidth
                        selectAllLabel="Select all"
                        optionLabel="name"
                        optionValue="id"
                        defaultSelected={rest?.value}
                        error={error}
                        helperText={error && error?.message}
                        {...rest}
                        inputRef={ref}
                      />
                    </Box>
                  );
                }}
              />
            </Box>
            {watchWorkRoleSkills?.length ? (
              <Box height="380px">
                <FdTable
                  toolbarSettings={{
                    hide: true,
                  }}
                  rows={watchWorkRoleSkills?.map((i, index) => ({
                    ...i,
                    difficulty: (
                      <Box width="100%">
                        <Controller
                          control={control}
                          name={`workRoleSkills[${index}].difficulty`}
                          render={({
                            field: { id, ref, value: fieldValue, ...rest },
                            fieldState: { error },
                          }) => (
                            <Box>
                              <FdSelect
                                key={id}
                                name={`workRoleSkills[${index}].difficulty`}
                                options={proficiency?.map((i) => ({
                                  ...i,
                                  name: (
                                    <Box display="flex">
                                      <FdTypography variant="body2">
                                        {`Level ${i?.id}`}
                                      </FdTypography>
                                      <Box ml={1}>
                                        <FdHighlightChip text={i?.name} />
                                      </Box>
                                    </Box>
                                  ),
                                }))}
                                defaultSelected={fieldValue}
                                fullWidth
                                error={error}
                                data-cy={`workRoleSkills[${index}].difficulty`}
                                placeholder="Select Proficiency"
                                {...rest}
                                inputRef={ref}
                                onChange={(value) => {
                                  setValue(
                                    `workRoleSkills[${index}].difficulty`,
                                    value,
                                  );
                                  clearErrors(
                                    `workRoleSkills[${index}].difficulty`,
                                  );
                                }}
                              />
                            </Box>
                          )}
                        />
                      </Box>
                    ),
                  }))}
                  columns={[
                    {
                      field: 'name',
                      width: 450,
                      headerName: (
                        <>{`Customer Skills Selected (${watchWorkRoleSkills?.length})`}</>
                      ),
                    },
                    {
                      field: 'difficulty',
                      flex: 1,
                      headerName: 'Assigned Proficiency Level',
                      renderCell: (params) => {
                        return <>{params?.value}</>;
                      },
                    },
                  ]}
                  tablePageSize={5}
                  gridId="work-role-list-org"
                />
                {errrorsMessage && (
                  <Box
                    className="flex flex-row-reverse	mr-6 mt-2"
                    color="#F44336"
                  >
                    <FdTypography variant="captiontext1">
                      Please select a Proficiency for each skill.
                    </FdTypography>
                  </Box>
                )}
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </FdModal>
      </form>
    </FormProvider>
  );
};

AddWorkRole.propTypes = {
  loading: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  isEdit: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  customerSkillData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  wRDetails: PropTypes.shape({
    id: PropTypes.string,
  }),
};

AddWorkRole.defaultProps = {
  isEdit: false,
  wRDetails: {},
};

export default AddWorkRole;
