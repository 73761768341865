import React from 'react';
import { Box } from '@mui/material';
import { FdCard, FdTypography, FdAlert } from '@fifthdomain/fe-shared';

const ScoreboardVisibility = () => {
  return (
    <FdCard variant="outlined" heading="Scoreboard Visibility">
      <Box my={2}>
        <FdAlert
          variant="info"
          message={
            <FdTypography variant="body2">
              Scoreboard Visibility will be determined by organisations using
              this template according to their own requirements.
            </FdTypography>
          }
        />
      </Box>
    </FdCard>
  );
};

export default ScoreboardVisibility;
