import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Box } from '@mui/material';
import { FdTable, FdChips } from '@fifthdomain/fe-shared';
import { getTagColor } from '../../../shared/utils/tagUtils';
import { sortByDateField } from '../../../shared/utils/dateUtils';
import TaskInfoCell from './TaskInfoCell';
import TableHeaderColumnWithTooltip from './TableHeaderColumnWithTooltip';
import {
  getCommaSeparatedPlusSuffix,
  capitalize,
} from '../../../shared/utils/stringUtils';
import { getDifficultyLevel } from '../../../shared/utils/difficultyMapping';
import { PROFICIENCY_LEVELS } from '../../../constants';

const getTableData = (tData, _tasks) =>
  tData?.map((d) => ({
    ...d,
  })) || [];

const TasksTable = ({
  data,
  editTasks,
  setEditTasksCallBack,
  setOpenDrawer,
  setActiveTask,
  viewAssessment,
  showEditButton,
  listHintRevealsData,
  competitionStatus,
  onEditMode,
}) => {
  const { control, setValue } = useFormContext();
  const [selectionModel, setSelectionModel] = useState([]);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    hintsReleased: !editTasks,
    hintsRevealAll: !editTasks && competitionStatus !== 'Ended',
    difficulty: false,
    estimatedSolveTime: false,
    technologyTags: false,
    techniqueTags: false,
    creator: false,
  });

  const { fields: tasks } = useFieldArray({
    control,
    name: 'tasks',
  });

  const tableData = useMemo(() => getTableData(data, tasks), [data, tasks]);

  const viewTaskActions = [
    {
      label: 'View',
      onClick: ({ id }) => {
        setOpenDrawer(true);
        const activeTaskData = tableData?.find((t) => t.id === id);
        setActiveTask(activeTaskData);
      },
    },
  ];

  useEffect(() => setSelectionModel(tasks?.map((t) => t?.taskId)), [tasks]);

  const columns = [
    {
      field: 'name',
      width: 300,
      headerName: 'Challenge Name',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <TaskInfoCell values={params.row} />,
    },
    {
      field: 'specialtyName',
      flex: 1,
      headerName: 'Professional Specialty',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Professional Specialty"
          tooltipText="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge can be classified."
        />
      ),
    },
    {
      field: 'skills',
      flex: 1,
      headerName: 'Skills',
      renderHeader: () => (
        <TableHeaderColumnWithTooltip
          title="Skills"
          tooltipText="Skills indicate the specific, acquired ability necessary to solve a challenge within a given timeframe or effort."
        />
      ),
      renderCell: (params) => getCommaSeparatedPlusSuffix(params.value),
    },
    {
      field: 'type',
      width: 100,
      headerName: 'Type',
      renderCell: (params) => capitalize(params?.value),
    },
    {
      field: 'difficulty',
      flex: 1,
      headerName: 'Proficiency',
    },
    {
      field: 'estimatedSolveTime',
      flex: 1,
      headerName: 'Est. Time to Solve (mins)',
      type: 'number',
    },
    {
      field: 'tags',
      flex: 1,
      headerName: 'Assigned Tags',
      valueGetter: (params) =>
        params.row?.tags?.length > 0
          ? [...params.row?.tags].map((t) => t.Tag?.name)?.join(' ')
          : '',
      renderCell: (params) => {
        const _data = params.row?.tags
          ?.sort(sortByDateField('updatedAt', 'desc'))
          .map((t) => ({
            label: t.Tag?.name,
            color: getTagColor(t.Tag?.color),
          }));
        return _data?.length > 0 ? (
          <FdChips data={_data} numberOfChipsDisplayed={1} caption="More" />
        ) : (
          <Box width="100%" textAlign="center">
            -
          </Box>
        );
      },
    },
    {
      field: 'recommendedPoints',
      width: 100,
      headerName: 'Points',
      type: 'number',
    },
  ];

  const editTask = viewAssessment || !editTasks ? editTasks : true;

  return (
    <Box mt={2} mb={2} height={editTask ? '650px' : '450px'} width="100%">
      <FdTable
        defaultMuiToolbarSettings={{
          showMuiDefaultToolbar: true,
          columnsButton: true,
          filterButton: true,
          densityButton: true,
        }}
        rowHeight={60}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        toolbarSettings={{
          title: `${!onEditMode ? 'Add Challenges' : 'Challenges'}`,

          headerActions:
            showEditButton && !editTasks
              ? [
                  {
                    label: 'EDIT',
                    onClick: () => {
                      setEditTasksCallBack(true);
                    },
                  },
                ]
              : [],

          filterOptions: [],
          searchBox: true,
          filterButton: editTask,
        }}
        selectionModel={selectionModel}
        onSelectionModelChange={(_values) => {
          setSelectionModel(_values);
          setValue(
            'tasks',
            _values.map((v) => ({ taskId: v })),
          );
        }}
        selection={editTask}
        disableSelectionOnClick
        actions={viewTaskActions}
        rows={
          tableData?.map((d) => {
            const _totalHints = d?.hints?.items;
            const _hintRevealed =
              listHintRevealsData?.listHintReveals?.items?.filter((item) =>
                item?.taskAssessmentId?.includes(d?.taskAssessmentId),
              );
            const _remainToRevealHints = _totalHints
              ?.filter(
                ({ id }) =>
                  !_hintRevealed?.map((item) => item?.hintId).includes(id),
              )
              ?.map((item) => ({
                hintId: item?.id,
                taskAssessmentId: d?.taskAssessmentId,
                challengeName: d?.name,
              }));
            return {
              ...d,
              difficulty:
                d.difficulty > 5
                  ? getDifficultyLevel(d.difficulty)
                  : PROFICIENCY_LEVELS[d.difficulty],
              hintsReleased: _totalHints?.length
                ? `${_hintRevealed?.length}(out of ${_totalHints?.length})`
                : '-',
              hintsRevealAll: _totalHints?.length ? _remainToRevealHints : null,
            };
          }) || []
        }
        columns={columns}
        pagination
        visibleSelection
        rowsPerPageOptions={[5, 10, 20]}
        tablePageSize={10}
        gridId="org-list-tasks"
      />
    </Box>
  );
};

TasksTable.defaultProps = {
  editTasks: true,
  setEditTasksCallBack: () => {},
  viewAssessment: false,

  showEditButton: undefined,
  listHintRevealsData: {},
  setOpenDrawer: () => {},
  setActiveTask: () => {},
  competitionStatus: null,

  onEditMode: false,
};

TasksTable.propTypes = {
  competitionStatus: PropTypes.string,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      category: PropTypes.string,
      difficulty: PropTypes.number,
      solveTime: PropTypes.number,
      recommendedPoints: PropTypes.number,
    }).isRequired,
  ).isRequired,
  editTasks: PropTypes.bool,
  setEditTasksCallBack: PropTypes.func,
  viewAssessment: PropTypes.bool,
  setOpenDrawer: PropTypes.func,
  setActiveTask: PropTypes.func,
  showEditButton: PropTypes.bool,
  listHintRevealsData: PropTypes.shape({
    listHintReveals: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          hintId: PropTypes.string,
          taskAssessmentId: PropTypes.string,
        }),
      ),
    }),
  }),

  onEditMode: PropTypes.bool,
};

export default TasksTable;
