import React from 'react';

import { Box } from '@mui/material';

import {
  FdCard,
  FdTypography as Typography,
  FdAlert,
} from '@fifthdomain/fe-shared';

const Availability = () => {
  return (
    <FdCard variant="outlined" heading="Availability">
      <Box my={2}>
        <FdAlert
          variant="info"
          message={
            <Typography variant="body2">
              Availability will be inputted by organisations using this template
              according to their own schedule requirements. FD Admin cannot
              determine template Availability.
            </Typography>
          }
        />
      </Box>
    </FdCard>
  );
};

export default Availability;
