import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import {
  useForm,
  FormProvider,
  Controller,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import {
  FdTextField,
  FdButton,
  FdModal,
  FdAutocomplete,
  FdAccordion,
  FdDelayed,
  FdAlert,
  FdTypography,
} from '@fifthdomain/fe-shared';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  addInitialValues,
  addValidationSchema,
} from '../../../validation-schemas/WorkRole/customerSkills';
import FdBoxButton from './FdBoxButton';

const AddCustomerSKill = ({
  fdSkills,
  openModal,
  onConfirm,
  onDismiss,
  loading,
}) => {
  const reactHookFormMethods = useForm({
    defaultValues: addInitialValues,
    resolver: yupResolver(addValidationSchema),
    mode: 'all',
  });
  const {
    control,
    trigger,
    reset,
    getValues,
    formState: { errors },
  } = reactHookFormMethods;

  const {
    fields: addCustomerSkillsField,
    remove: addCustomerSkillsRemove,
    append: addCustomerSkillsAppend,
  } = useFieldArray({
    control,
    name: 'addCustomerSkills',
  });

  const watchCustomerSkills = useWatch({
    control,
    name: 'addCustomerSkills',
  });

  return (
    <FormProvider {...reactHookFormMethods}>
      <form style={{ width: '100%' }}>
        <FdModal
          size="lg"
          title="Add Customer Skill(s)"
          confirm={loading ? 'Loading' : 'Save'}
          disableConfirm={loading}
          dismiss="CANCEL"
          open={openModal}
          onConfirm={async () => {
            const result = await trigger();
            if (result) {
              const { addCustomerSkills } = getValues();
              onConfirm(
                {
                  addCustomerSkills,
                },
                reset,
              );
            }
          }}
          onDismiss={() => {
            reset();
            onDismiss();
          }}
          data-cy="open-modal"
        >
          {errors?.addCustomerSkills?.length && (
            <Box mb={2}>
              <FdAlert
                variant="error"
                message={`There are ${errors?.addCustomerSkills?.length} customer skills with errors. Please resolve errors before clicking Save.`}
              />
            </Box>
          )}

          <Box mt={3}>
            {addCustomerSkillsField?.map((item, index) => {
              return (
                <Box my={2} key={item.id}>
                  <FdAccordion
                    style={{
                      borderColor: errors?.addCustomerSkills?.length
                        ? '#E57387'
                        : null,
                    }}
                    open
                    startAdornment
                    showToggleButton={false}
                    summary={() => (
                      <Box display="flex" width="100%">
                        <FdTypography variant="h3">
                          {watchCustomerSkills?.[index]?.customerSkillName ||
                            'Customer Skill'}
                        </FdTypography>
                      </Box>
                    )}
                    actions={
                      <Box ml={1}>
                        <FdButton
                          variant="secondary"
                          size="small"
                          onClick={(e) => {
                            e.preventDefault();
                            addCustomerSkillsRemove(index);
                          }}
                        >
                          DELETE
                        </FdButton>
                      </Box>
                    }
                    content={() => (
                      <Box display="flex" flexDirection="column" width="100%">
                        <Controller
                          control={control}
                          name={`addCustomerSkills[${index}].customerSkillName`}
                          render={({
                            field: { ref, ...rest },
                            fieldState: { error },
                          }) => (
                            <Box mt={1}>
                              <FdTextField
                                id={`addCustomerSkills[${index}].customerSkillName`}
                                label="Customer Skill Name"
                                placeholder="Enter Customer Skill Name"
                                required
                                fullWidth
                                error={error}
                                helperText={error?.message}
                                {...rest}
                                inputRef={ref}
                              />
                            </Box>
                          )}
                        />
                        <Box my={3}>
                          <Controller
                            control={control}
                            name={`addCustomerSkills[${index}].fdSkill`}
                            render={({
                              field: { ref, value: fieldValue, ...rest },
                              fieldState: { error },
                            }) => (
                              <FdDelayed>
                                <Box>
                                  <FdAutocomplete
                                    id={`addCustomerSkills[${index}].fdSkill`}
                                    label="Mapped FifthDomain Skill(s)"
                                    placeholder="Select FifthDomain Skill(s)"
                                    options={fdSkills}
                                    multiple
                                    fullWidth
                                    error={error}
                                    helperText={error && error.message}
                                    data-cy={`addCustomerSkills[${index}].fdSkill`}
                                    selectAllLabel="Select all"
                                    optionLabel="name"
                                    optionValue="id"
                                    defaultSelected={fieldValue}
                                    {...rest}
                                    inputRef={ref}
                                  />
                                </Box>
                              </FdDelayed>
                            )}
                          />
                        </Box>
                      </Box>
                    )}
                  />
                </Box>
              );
            })}
            <FdBoxButton
              onClick={async (e) => {
                e.preventDefault();
                addCustomerSkillsAppend({});
              }}
            >
              Add Customer skill
            </FdBoxButton>
          </Box>
        </FdModal>
      </form>
    </FormProvider>
  );
};

AddCustomerSKill.propTypes = {
  loading: PropTypes.bool.isRequired,
  openModal: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  fdSkills: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default AddCustomerSKill;
