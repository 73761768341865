import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import * as Yup from 'yup';
import { Box, IconButton, useTheme } from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  BasePage,
  FdCardList,
  FdButton,
  useQueryRecursive,
  FdProgress,
  FdModal,
  FdTable,
  FdTypography,
  FdTooltip,
  FdCheckbox,
  useSnapshot,
  globalStore,
  FdAlert,
  successToastMessage,
  warningToastMessage,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { listTasksByMode, listTasks } from '../graphql/queries';
import {
  listTaskAttemptAggregates,
  listTaskOrgs,
} from '../queries/customQueries';
import CatalogAccordion from '../components/ContentCatalogue/CatalogAccordion';
import CatalogueTile from '../components/ContentCatalogue/CatalogueTile';
import OrgContentCatalogue from '../components/ContentCatalogue/OrgContentCatalogue';
import { CONTENT_LEASE_CATALOG_PRICE } from '../constants';
import ChallengeInfoCell from '../components/Task/ChallengeInfoCell';
import CatalogueTabs from '../components/ContentCatalogue/CatalogueTabs';
import { allocateTasksToOrg } from '../graphql/mutations';
import { getDifficultyLevel } from '../shared/utils/difficultyMapping';

const ContentLeaseCatalogue = () => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const [
    allocateTasksToOrgMutation,
    { loading: allocateTasksToOrgMutationLoading },
  ] = useMutation(gql(allocateTasksToOrg));

  const validationSchema = Yup.object().shape({
    taskIds: Yup.array().of(Yup.string()).min(1),
    confirm: Yup.boolean().required(),
  });

  const reactHookFormMethods = useForm({
    defaultValues: { taskIds: [], confirm: false },
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { watch, control, setValue, reset } = reactHookFormMethods;
  const watchTaskIds = watch('taskIds');
  const watchConfirm = watch('confirm');

  const { data: listTaskAttemptsData, loading: listTaskAttemptsLoading } =
    useQueryRecursive(gql(listTaskAttemptAggregates), {
      variables: {
        filter: {
          status: { eq: 'COMPLETED' },
        },
      },
      skip: !globalSnap?.orgId,
    });

  const {
    data: listTasksOrgsData,
    loading: listTasksOrgsLoading,
    refetch: listTasksOrgsRefetch,
  } = useQueryRecursive(gql(listTaskOrgs), {
    variables: {
      filter: {
        orgId: { eq: globalSnap?.orgId },
      },
      limit: 1000,
    },
    skip: !globalSnap?.orgId,
  });

  const { data: listTasksData, loading: listTasksLoading } = useQueryRecursive(
    gql(listTasks),
    {
      variables: {
        filter: {
          status: { ne: 'DRAFT' },
          orgId: { eq: globalSnap?.orgId },
        },
      },
      skip: !globalSnap?.orgId,
    },
  );

  const {
    data: listTasksByModeData,
    loading: listTasksByModeLoading,
    refetch: listTasksByModeRefetch,
  } = useQueryRecursive(gql(listTasksByMode), {
    variables: {
      public: 'TRUE',
      limit: 500,
    },
    skip: !globalSnap?.orgId,
  });

  if (
    !globalSnap?.orgId ||
    listTaskAttemptsLoading ||
    listTasksLoading ||
    listTasksOrgsLoading ||
    listTasksByModeLoading
  ) {
    return <FdProgress />;
  }
  // get tasks from tasksOrg and list tasks to combine the tasks list
  const tasksPublicData = listTasksByModeData?.listTasksByMode?.items || [];
  const currentTasksData = listTasksData?.listTasks?.items || [];
  const currentTasksOrgData = listTasksOrgsData?.listTaskOrgs?.items || [];
  const combinedTasksIds = currentTasksData
    ?.map((ct) => ct?.id)
    .concat(currentTasksOrgData?.map((cto) => cto?.task?.id));
  const tasksPublicDataExcludingCurrentTasks = tasksPublicData.filter(
    (p) => !combinedTasksIds.includes(p.id),
  );
  const getVMCount = (_task) => {
    const vms = _task?.lab?.vms?.items?.length || 0;
    return vms;
  };

  const getTaskType = (_task) =>
    _task?.lab !== null ? `Lab (VMs: ${getVMCount(_task)})` : 'Static';

  const currentTasksDataFormatted = currentTasksData?.map((task) => ({
    ...task,
    type: getTaskType(task),
  }));

  const tableData = tasksPublicDataExcludingCurrentTasks?.map((task) => ({
    ...task,
    specialtyName: task?.specialty?.name,
    workRoles: [
      ...new Set(
        task.competencies?.items
          ?.map((competencyData) => competencyData?.workRole?.workRole)
          .flat(),
      ),
    ],
    skills: task?.specialty?.skills?.items.map((s) => s.name),
    techniqueTags: task?.skills?.items
      .map((s) => s.techniqueTags?.items.map((st) => st.techniqueTag?.name))
      .flat(),
    technologyTags: task?.technologyTags?.items.map(
      (t) => t.technologyTag?.name,
    ),
    assigned: [
      ...new Set(task.orgs?.items?.map((orgData) => orgData?.org?.name).flat()),
    ],
    type: getTaskType(task),
    attempts:
      listTaskAttemptsData?.listTaskAttemptAggregates?.items.filter(
        (a) => a.taskId === task?.id,
      )?.length || 0,
    difficulty: getDifficultyLevel(task?.difficulty),
    difficultyInteger: task?.difficulty,
  }));

  // summary calculation
  const tasksSelected = tableData.filter((t) => watchTaskIds.includes(t.id));
  const getTaskTypeCount = (_type) =>
    tasksSelected?.filter((t) => t.type.includes(_type))?.length || 0;
  const staticContentCount = getTaskTypeCount('Static');
  const labsContentCount = getTaskTypeCount('Lab');
  const staticContentCost =
    staticContentCount * CONTENT_LEASE_CATALOG_PRICE.staticContent;
  const labsContentCost =
    labsContentCount * CONTENT_LEASE_CATALOG_PRICE.labsContent;
  const totalCost = staticContentCost + labsContentCost;

  const getCostDescription = (_type) => {
    let description = '';
    switch (_type) {
      case 'Static':
        description = `$${CONTENT_LEASE_CATALOG_PRICE.staticContent} One-off`;
        break;
      case 'Labs':
        description = `$${CONTENT_LEASE_CATALOG_PRICE.labsContent} One-off + Compute + Storage`;
        break;
      default:
        break;
    }
    return description;
  };

  const summaryTableRows = tasksSelected?.map((t, idx) => ({
    ...t,
    slNo: idx + 1,
    estimatedSolveTime: `${t.estimatedSolveTime} (mins)`,
    cost: getCostDescription(t.type?.includes('Lab') ? 'Labs' : t.type),
  }));

  const summaryTableColumns = [
    { field: 'slNo', headerName: '', width: '50px' },
    { field: 'name' },
    { field: 'points' },
    {
      field: 'attempts',
      headerName: 'Attempts',
      valueGetter: (params) =>
        params.row.attempts > 0 ? '' : 'Never Solved before',
    },
    {
      field: 'difficulty',
    },
    { field: 'estimatedSolveTime' },
    {
      field: 'challengeInfo',
      width: 450,
      headerName: 'Challenges to be Leased',
      valueGetter: (params) => params.row.name,
      renderCell: (params) => <ChallengeInfoCell values={params.row} />,
    },
    {
      field: 'cost',
      flex: 200,
      renderHeader: () => {
        return (
          <Box display="flex" alignItems="center">
            <FdTypography variant="subtitle2">Cost</FdTypography>
            <FdTooltip
              title={
                <Box>
                  Static and lab challenges each have a one-off lease cost.
                  <br />
                  <br />
                  Lab Challenges have additional compute and storage costs based
                  on usage.
                  <br />
                  <br />
                  Your billing will reflect these additional costs too (as
                  required).
                </Box>
              }
            >
              <IconButton size="small" style={{ marginLeft: '10px' }}>
                <InfoOutlinedIcon />
              </IconButton>
            </FdTooltip>
          </Box>
        );
      },
    },
  ];
  const groupLabs = (t) => ({
    ...t,
    type: t.type?.includes('Lab') ? 'Labs' : t.type,
  });

  return (
    <Box>
      <FdBreadcrumbHeader
        page={{ name: 'Lease Catalogue', type: 'LEASE_CATALOGUE' }}
      />
      <BasePage
        type="LEASE_CATALOGUE"
        heading="FifthDomain Challenges Store"
        data-cy="content-catalogue"
      >
        <CatalogAccordion />
        <FormProvider {...reactHookFormMethods}>
          <form>
            <FdCardList
              data={[
                <CatalogueTile
                  titleText={`Selected Static Challenges: ${staticContentCount}`}
                  tooltipText={`Each static Challenge is a one-off $${CONTENT_LEASE_CATALOG_PRICE.staticContent} AUD cost to your organisation.`}
                  value={`$${staticContentCost}`}
                  caption="One-off"
                />,
                <CatalogueTile
                  titleText={`Selected Lab Challenges: ${labsContentCount}`}
                  tooltipText={`Each lab Challenge is a one-off $${CONTENT_LEASE_CATALOG_PRICE.labsContent} AUD cost to your organisation, plus compute costs when used in a competition/assessment (~$2.5 AUD/hour), and storage costs ($0.9 AUD/VM/day).`}
                  value={`$${labsContentCost}`}
                  caption="One-Off + Compute + Storage"
                />,
                <CatalogueTile
                  titleText={`Total Selected Challenges: ${tasksSelected?.length}`}
                  value={`$${totalCost}`}
                  caption={
                    labsContentCount > 0
                      ? 'One-Off + Compute + Storage'
                      : 'One-off'
                  }
                  emphasisValue
                />,
                <FdButton
                  startIcon={<ShoppingCartIcon />}
                  onClick={() => {
                    setValue('confirm', false);
                    setShowModal(true);
                  }}
                  disabled={watchTaskIds?.length === 0}
                >
                  Confirm Lease
                </FdButton>,
              ]}
            />
            <Box className="flex gap-x-4">
              <Box width={openDrawer ? '100%' : '80%'}>
                <OrgContentCatalogue
                  data={tableData}
                  openDrawer={openDrawer}
                  setOpenDrawer={setOpenDrawer}
                />
              </Box>
              <Box width={openDrawer ? '0%' : '20%'}>
                <CatalogueTabs
                  tasks={currentTasksDataFormatted
                    .concat(tasksSelected)
                    ?.map(groupLabs)}
                  currentTasksData={currentTasksDataFormatted?.map(groupLabs)}
                />
              </Box>
            </Box>
            <FdModal
              size="lg"
              title="FifthDomain Challenges Store Transaction"
              description="The Challenges  selected for lease by your organisation are listed below. Please ensure that you check the list below carefully before proceeding with the transaction."
              confirm="Confirm Transaction"
              dismiss="Cancel"
              open={showModal}
              onConfirm={() => {
                if (watchTaskIds?.length > 0) {
                  allocateTasksToOrgMutation({
                    variables: {
                      orgId: globalSnap?.orgId,
                      taskIds: watchTaskIds,
                    },
                    onCompleted: () => {
                      reset();
                      setShowModal(false);
                      successToastMessage(
                        'Success! Challenges lease transaction made',
                      );
                      listTasksByModeRefetch();
                      listTasksOrgsRefetch();
                    },
                  });
                }
              }}
              onDismiss={() => {
                setShowModal(false);
                warningToastMessage('Challenges lease transaction not made');
              }}
              disableConfirm={
                !watchConfirm || allocateTasksToOrgMutationLoading
              }
              confirmLoading={allocateTasksToOrgMutationLoading}
            >
              <Box mt={1}>
                <FdAlert
                  variant="info"
                  message="Once you confirm this transaction, you can close this browser tab. If you opened the store screen from another tab where you were creating a competition or an assessment, you can click the “Refresh Challenges” button (on your original tab) to load newly leased challenges to be available for allocation to your competition/assessment."
                />
              </Box>
              <Box mt={2}>
                <FdTypography variant="subtitle1">
                  Challenge Selected for Leasing Rights
                </FdTypography>
              </Box>
              <Box mt={2} mb={2} height="450px" data-cy="tasks-summary-table">
                <FdTable
                  columnVisibilityModel={{
                    estimatedSolveTime: false,
                    difficulty: false,
                    attempts: false,
                    name: false,
                    points: false,
                  }}
                  toolbarSettings={{
                    hide: true,
                  }}
                  rows={summaryTableRows}
                  columns={summaryTableColumns}
                  pagination
                  rowsPerPageOptions={[5, 10, 20]}
                  tablePageSize={5}
                  gridId="org-content-lease-catalogues"
                />
              </Box>
              <Box display="flex" justifyContent="flex-end" alignItems="center">
                <FdTypography variant="subtitle1">
                  Total One-Off Lease Transaction Cost:
                </FdTypography>
                <FdTypography
                  style={{
                    fontWeight: 'bold',
                    fontSize: '16',
                    color: theme.palette.primary.main,
                    marginLeft: '20px',
                    borderRadius: '4px',
                    border: `1px solid ${theme.palette.background.border}`,
                    padding: '0.5rem 1rem',
                  }}
                >
                  {`$${totalCost}`}
                </FdTypography>
              </Box>
              <Box mt={2}>
                <Box mb={1}>
                  <FdTypography variant="subtitle1">
                    {`Confirm leasing rights purchase of ${tasksSelected?.length}  Challenge(s)?`}
                  </FdTypography>
                </Box>
                <Controller
                  control={control}
                  name="confirm"
                  render={({ field: { ref, value, ...fieldProps } }) => (
                    <FdCheckbox
                      label="I agree to the terms of use of challenge leasing, and understand that this transaction will reflect in my organisation’s billing and cannot be refunded."
                      inputRef={ref}
                      checked={value}
                      {...fieldProps}
                    />
                  )}
                />
              </Box>
            </FdModal>
          </form>
        </FormProvider>
      </BasePage>
    </Box>
  );
};

export default ContentLeaseCatalogue;
