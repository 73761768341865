import React from 'react';
import { Box } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { gql } from '@apollo/client';
import * as singleSpa from 'single-spa';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import BookIcon from '@mui/icons-material/Book';
import {
  BasePage,
  FdTypography,
  FdLoadingSpinner,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTab,
  FdSelect,
} from '@fifthdomain/fe-shared';
import { listTemplates } from '../graphql/queries';
import { TemplateTable } from '../components/Template';
import { productStyle } from '../shared/utils/layout';
import { sortedByCreatedDate } from '../shared/utils/dateUtils';

const useStyles = makeStyles()(() => ({
  styledSelect: {
    '& [class*="MuiOutlinedInput"]': {
      padding: 0,
    },
    marginTop: '-10px',
  },
}));

const ListTemplates = () => {
  const { classes } = useStyles();
  const globalSnap = useSnapshot(globalStore);

  const {
    data: listTemplatesData,
    loading: listTemplatesLoading,
    refetch: refetchlistTemplates,
  } = useQueryRecursive(gql(listTemplates), {
    variables: {
      orgId: globalSnap.orgId,
    },
    skip: !globalSnap.orgId,
  });

  if (listTemplatesLoading) {
    return <FdLoadingSpinner />;
  }

  const eventsData =
    listTemplatesData?.listTemplates?.items?.map((template) => {
      return {
        ...template,
        creator: template?.creator?.name,
        proficiency: '',
        type: template.teamBased ? 'Team' : 'Individual',
        icon: (
          <Box style={productStyle(template?.participantEventType)}>
            {template?.participantEventType === 'ASSESSMENT' ? (
              <AssignmentIcon style={{ height: '18px' }} />
            ) : (
              <EmojiEventsIcon style={{ height: '18px' }} />
            )}
          </Box>
        ),
      };
    }) || [];

  const allReleased = eventsData?.filter(
    (allRelease) => allRelease.status === 'RELEASED',
  );

  const allUnReleased = eventsData?.filter(
    (allUnRelease) => allUnRelease.status !== 'RELEASED',
  );

  const allCompetitions = eventsData?.filter(
    (allCompetition) => allCompetition.participantEventType === 'COMPETITION',
  );
  const allAssessments = eventsData?.filter(
    (allCompetition) => allCompetition.participantEventType !== 'COMPETITION',
  );

  const numberOfEvents =
    (allReleased?.length > 0 ? 1 : 0) +
    (allUnReleased?.length > 0 ? 1 : 0) +
    (allCompetitions.length > 0 ? 1 : 0) +
    (allAssessments.length > 0 ? 1 : 0);

  const tabLabel = [
    numberOfEvents > 1 && {
      label: (
        <Box mr={1} ml={1}>
          <FdTypography variant="body2">All</FdTypography>
        </Box>
      ),
      path: '/all',
      index: 0,
      data: (
        <TemplateTable
          rows={sortedByCreatedDate(eventsData)}
          refetchTemplateData={refetchlistTemplates}
        />
      ),
    },
    allReleased?.length && {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('')} mr={0.5}>
            <AssignmentTurnedInIcon style={{ marginTop: '4px' }} />
          </Box>
          <FdTypography variant="body2">{`Released Templates (${allReleased?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allReleased',
      index: 1,
      data: (
        <TemplateTable
          rows={sortedByCreatedDate([...allReleased])}
          refetchTemplateData={refetchlistTemplates}
        />
      ),
    },

    allUnReleased?.length && {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('')} mr={0.5}>
            <BookIcon style={{ marginTop: '4px' }} />
          </Box>
          <FdTypography variant="body2">{`Unreleased Templates (${allUnReleased?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allUnReleased',
      index: 2,
      data: (
        <TemplateTable
          rows={sortedByCreatedDate([...allUnReleased])}
          refetchTemplateData={refetchlistTemplates}
        />
      ),
    },
    allCompetitions?.length && {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('COMPETITION')} mr={0.5}>
            <EmojiEventsIcon style={{ marginTop: '4px' }} />
          </Box>
          <FdTypography variant="body2">{`Competitions (${allCompetitions?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allCompetitions',
      index: 3,
      data: (
        <TemplateTable
          rows={sortedByCreatedDate([...allCompetitions])}
          refetchTemplateData={refetchlistTemplates}
        />
      ),
    },
    allAssessments?.length && {
      label: (
        <Box display="flex" alignItems="center">
          <Box style={productStyle('ASSESSMENT')} mr={0.5}>
            <AssignmentIcon style={{ marginTop: '4px' }} />
          </Box>
          <FdTypography variant="body2">{`Assessments (${allAssessments?.length})`}</FdTypography>
        </Box>
      ),
      path: '/allAssessments',
      index: 4,
      data: (
        <TemplateTable
          rows={sortedByCreatedDate([...allAssessments])}
          refetchTemplateData={refetchlistTemplates}
        />
      ),
    },
  ];

  return (
    <BasePage data-cy="welcome-card" heading="Template Library">
      <FdCard
        variant="outlined"
        style={{ marginTop: '0.8rem' }}
        heading={
          <Box
            display="flex"
            justifyContent="space-between"
            style={{ marginBottom: '-32px' }}
          >
            <FdTypography variant="subtitle1">
              FifthDomain Templates
            </FdTypography>
            <Box mx={1} className={classes.styledSelect}>
              <FdSelect
                options={['Competition', 'Assessment']}
                width="160px"
                placeholder="CREATE"
                customPlaceHolder
                onChange={(_value) => {
                  const url = {
                    Competition: 'competition',
                    Assessment: 'assessment',
                  };
                  singleSpa.navigateToUrl(
                    `/org/template-library/create/${url[_value]}`,
                  );
                }}
              />
            </Box>
          </Box>
        }
      >
        {numberOfEvents > 0 && (
          <FdTab label={tabLabel?.filter((item) => item)} />
        )}
      </FdCard>
    </BasePage>
  );
};
export default ListTemplates;
